import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';
import Loading from '../../../Loading/Loading';
import Chat from './Chat';

import chatcover from '../../../../files/avocado_dark.png'
import logo from '../../../../files/banners/GYMi/LOGO/logo3.png'
import logo2 from '../../../../files/banners/GYMi/LOGO/logo.png'
import Invite from './Invite';
import '../../../styles/Cpanel/Gymi/Gymiworkout.css'

const { Config } = require('../../../config');


class GymiWorkout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies() , conversations:[] , catchData: false , conversationsInfo:[] , currentChatId: null};
        // this.get_conversations_id = this.get_conversations_id.bind(this);
        // this.rerender = this.rerender.bind(this);
    }
    rerender() {
        this.setState({cookies: new Cookies() , conversations:[] , catchData: false , conversationsInfo:[] , currentChatId: null});
    }


    // chatenter(id , type) {
    //     let chat = "chat" + id;
    //     if(type == "Demo") {
    //         document.getElementById(chat).style.borderColor = "rgb(0, 120, 0)";
    //         document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "1";
    //         document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "rgb(0,0,0)";
    //     }
    //     else {
    //         document.getElementById(chat).style.borderColor = "rgb(0, 120, 0)";
    //         document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "0.6";
    //         document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "rgb(210,210,210)";
    //     }
    // }
    // chatleave(id , type) {
    //     let chat = "chat" + id;
    //     if(type == "Demo") {
    //         document.getElementById(chat).style.borderColor = "rgb(50,50,50)";
    //         document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "0.4";
    //         document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "rgb(150,150,150)";
    //     }
    //     else {
    //         document.getElementById(chat).style.borderColor = "rgb(50,50,50)";
    //         document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "0.2";
    //         document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "rgb(100,100,100)";
    //     }
    // }

    // get_conversations_id() {
    //     let response = [] , conversationsId = [] , infos = [];
    //     axios.get(
    //         Config.BASE_URL + "/chat/conversations/" + this.state.cookies.get("username"),
    //         {   
    //             crossDomain: true,
    //             withCredentials: false,
    //             headers: {
    //                 'authorization': this.state.cookies.get("user-token"),
    //                 "Access-Control-Allow-Origin": "*"
    //             }
    //         }
    //     ).then(res => {
    //         console.log(res);
    //         console.log(res.data);
    //         response = res.data;

    //         for(let i = 0; i < response.length; ++i) {
    //             conversationsId.push(response[i].id);
    //             let info = [];
    //             info.push(response[i].senario);
    //             info.push(response[i].has_plan);
    //             info.push(response[i].name);
    //             infos.push(info);
    //         }
    //         this.setState({conversations: conversationsId , conversationsInfo: infos , catchData: true});
    //     })
    // }

    // chatcmp(index , type) {
    //     if(index == -1) {
    //         return(
    //             <div className='chat invite' onClick={() => this.openInviteModal()}>
    //                 <span>+</span>
    //             </div>
    //         );
    //     }
    //     let info = this.state.conversationsInfo[index];
    //     return(
    //         <div className={'chat ' + type} id={"chat"+index.toString()} onClick={() => this.openChatModal(index)} onMouseEnter={() => this.chatenter(index.toString() , type)} onMouseLeave={() => this.chatleave(index.toString() , type)}>
    //             <img className='chatcover' src={type == "Demo" ? logo:logo2}></img>
    //             <p className='chat--name'>{info[2]}</p>
    //         </div>
    //     );
    // }

    // chatdemo() {
    //     let list = [];
    //     let len = this.state.conversations.length;
    //     for(let i = 0; i < len; i += 1) {
    //         if(this.state.conversationsInfo[i][0] == "Demo") {
    //             list.push(this.chatcmp(i , "Demo"));
    //             break;
    //         }
    //     }
    //     return list;
    // }
    // chatsmentor() {
    //     let list = [];
    //     let len = this.state.conversations.length;
    //     for(let i = 0; i < len; i += 1) {
    //         if(this.state.conversationsInfo[i][0] == "Mentor") {
    //             list.push(i);
    //         }
    //     }
    //     list.push(-1);
    //     let list2 = [];
    //     for (let i = 0; i < list.length; i += 2) {
    //         if(i + 1 < list.length) {
    //             list2.push(
    //                 <div className='row'>
    //                     {this.chatcmp(list[i] , "Mentor")}
    //                     {this.chatcmp(list[i + 1] , "Mentor")}
    //                 </div>
    //             );
    //         }
    //         else {
    //             list2.push(
    //                 <div className='row'>
    //                     {this.chatcmp(list[i] , "Mentor")}
    //                 </div>
    //             );
    //         }
    //     }
    //     list = list2;
    //     list.push(<div className='chat--modal'>
    //                 <div className='modal--chatbox'>
    //                     <Chat id={this.state.currentChatId} parentRefresh={this.rerender} parentCloseChat={this.closeChatModal}/>
    //                 </div>
    //             </div>
    //     );
    //     list.push(<div className='invite--modal'>
    //                 <div className='modal--invitebox'>
    //                     <Invite parentCloseModal={this.closeInviteModal}/>
    //                 </div>
    //             </div>
    //     );
    //     return list;
    // }
    // openChatModal(index) {
    //     document.getElementsByClassName("chat--modal")[0].style.display = "flex";
    //     document.getElementsByClassName("chat--modal")[0].style.visibility = "visible";

    //     this.setState({currentChatId: this.state.conversations[index]});
    //     setTimeout(() => {
    //         document.getElementsByClassName("modal--chatbox")[0].style.opacity = "1";
    //     } , "100");
    // }
    // closeChatModal() {
    //     document.getElementsByClassName("modal--chatbox")[0].style.opacity = "0";
    //     setTimeout(() => {
    //         document.getElementsByClassName("chat--modal")[0].style.display = "none";
    //     } , "300");
    // }
    // openInviteModal() {
    //     document.getElementsByClassName("invite--modal")[0].style.display = "flex";
    //     document.getElementsByClassName("invite--modal")[0].style.visibility = "visible";

    //     setTimeout(() => {
    //         document.getElementsByClassName("modal--invitebox")[0].style.opacity = "1";
    //     } , "100");
    // }
    // closeInviteModal() {
    //     document.getElementsByClassName("modal--invitebox")[0].style.opacity = "0";
    //     setTimeout(() => {
    //         document.getElementsByClassName("invite--modal")[0].style.display = "none";
    //     } , "300");
    // }


    render() {

        // window.onclick = function(event) {
        //     if(event.target.className ==  "chat--modal") {
        //         document.getElementsByClassName("modal--chatbox")[0].style.opacity = "0";
        //         setTimeout(() => {
        //             document.getElementsByClassName("chat--modal")[0].style.display = "none";
        //         } , "300");
        //     }
        //     if (event.target.className ==  "endconv--modal" || event.target.className == "disagree") {
        //         document.getElementsByClassName("modal--endverify")[0].style.opacity = "0";
        //         setTimeout(() => {
        //             document.getElementsByClassName("endconv--modal")[0].style.display = "none";
        //         } , "300");
        //     }
        //     if (event.target.className == "delconv--modal" || event.target.className == "disagree") {
        //         document.getElementsByClassName("modal--delverify")[0].style.opacity = "0";
        //         setTimeout(() => {
        //             document.getElementsByClassName("delconv--modal")[0].style.display = "none";
        //         } , "300");
        //     }
        // }  

        // if(this.state.catchData == false) {
        //     setTimeout(() => {
        //         this.get_conversations_id();
        //     }, 500)
        //     return(
        //         <Loading />
        //     );
        // }

        return (
            <div className ='gymi_workout'>
                {/* <div className='controlbox'>
                    <button className='back' onClick={() => setTimeout(() => {this.props.parentRefresh()} , "500")}>&lt;</button>
                    <div className='topic'><span>Gymi Diet</span></div>
                </div>
                <div className='chats'>
                    <div className='topic--demo'>
                        <span>DEMO</span>
                    </div>
                    <div className='demo'>
                        {this.chatdemo()}
                    </div>
                    <div className='topic--mentor'>
                        <span>MENTOR</span>
                    </div>
                    <div className='mentor'>
                        {this.chatsmentor()}
                    </div>
                </div> */}

                <div className='commingsoon'>
                    <span>
                        <ReactTyped 
                            strings={["به زودی"]} typeSpeed={100} showCursor={false} startDelay= {1000} backSpeed={100} loop
                            // style={{whiteSpace: 'pre-line' , color: props.color}}
                        />
                    </span>
                </div>
            </div>
        );

    }
}

export default GymiWorkout