import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Wallet.css'
import '../../../styles/Cpanel/Wallet_v2.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';


class Deposite extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , disabled: true};

        this.setMode = this.setMode.bind(this);
        this.refresh = this.refresh.bind(this);
    }
    
    refresh() {
        setTimeout(() => {
            this.setState({mode: 'wallet'});
        }, "1000");
    }
    setMode(s) {
        setTimeout(() => {
            this.setState({mode: s});
        }, "1000");
    }

    send_data() {
        let amount  = document.getElementById("input_deposite").value;
        amount = amount.split(',').join('');
        amount = parseInt(amount , 10);

        axios.post(
            Config.BASE_URL + "/payment/userpayrequest/",
            {
                amount: amount,
                category: "Wallet",
                description: "افزایش موجودی کیف پول",
                callbackURL: "https://gymiai.com/deposite",
            },
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res.data);
            if(res.data.status == "succeed") {
                window.location.href = "https://payment.zarinpal.com/pg/StartPay/" + res.data.authority;
            }
        }).catch(error => {
            console.log(error);
        });
    }

    calc(balance) {
        let tmp = balance.toString();
        let res = "";
        for (let i = 0; i < tmp.length; ++i) {
            if(i > 0 && i % 3 == 0) {
                res += ",";
            }
            res += tmp[tmp.length - 1 - i];
        }
        return res.split('').reverse().join('');
    }

    inputrefresh() {
        this.buttonrefresh(0);
        let amount = document.getElementById("input_deposite").value;
        amount = amount.split(',').join('');
        if(amount.length == 0) amount = "0";
        
        let amount2 = parseInt(amount, 10);
        let res = this.calc(amount2);
        document.getElementById("input_deposite").value = res;
        if(amount2 >= 50000) {
            this.buttonrefresh(1);
        }
    }
    buttonrefresh(t) {
        let elem = document.getElementById("button_send");
        if(t == 0) {
            this.setState({disabled: true});
            elem.style.opacity = "0.2";
        }
        else {
            this.setState({disabled: false});
            elem.style.opacity = "1";
        }
    }

    render() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const balance = params.get('balance');
        return(
            <div className='deposite'>
                <div className='wallet--size'>
                    <span className='balance--topic'>موجودی</span>
                    <p className='balance'><span className='amount'>{this.calc(balance)}</span>تومان</p>
                </div>
                <div className='deposite--input'>
                    <input id='input_deposite' placeholder='مبلغ واریزی به تومان' onChange={() => this.inputrefresh()}></input>
                </div>
                <div className='controlbox'>
                    <button className='back' onClick={() => {window.history.back()}}>برگشت</button>
                    <button id='button_send' className='send' disabled={this.state.disabled} onClick={() => this.send_data()}>ادامه</button>
                </div>
            </div>
        );
    }
}

export default Deposite