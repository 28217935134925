import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';
import Loading from '../../../Loading/Loading';
const { Config } = require('../../../config');


class Invite extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies() , loadingErrorDialog : null , loadingSuccessDialog : null , log : "",
            loadingDialog: "", showLoading: false , loadingFading: false , loadingDialogShow: false
        };
    }

    handleShowLoading = () => {
        this.setState({showLoading: true, loadingFading: false , loadingDialog: "" , loadingDialogShow: false});
    };
    handleCloseLoading = () => {
        this.setState({loadingFading: true});
        setTimeout(() => {
            this.setState({showLoading: false, loadingFading: false , loadingDialog: "" , loadingDialogShow: false});
        }, 500); // Match this duration with the CSS transition duration
    };
    loadingDialog(dialog) {
        this.setState({loadingDialog: dialog , loadingDialogShow: true});
        setTimeout(() => {
            this.handleCloseLoading();
        } , "2000");
    }

    send() {
        let Phone = document.getElementsByClassName("phone")[0].value;

        this.handleShowLoading()
        axios.post(
            Config.BASE_URL + "/user/invitetrainee/",
            {
                phone_number: Phone,
            },
            {   
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            let response = res.data;
            if (response.status == "ok") {
                this.loadingDialog("دعوت نامه با موفقیت ارسال شد")
            }
        }).catch(error => {
            console.log(error);
            if(error.response.data.error == "invalid phone") {
                this.loadingDialog("شماره همراه معتبر نمیباشد");
            }
            else if(error.response.data.error == "phone already signedup") {
                this.loadingDialog("هنرجو قبلا با این شماره ثبت نام کرده است");
            }
            else {
                this.loadingDialog("خطایی پیش آمده دوباره تلاش کنید!");
            }
        });
    }

    render() {
        return (
        <>
            <div className='invite'>
                <div className='topic'><span>دعوت</span></div>
                <span className='describtion'>هنرجوی خود را به پلتفرم GYMi دعوت کنید تا پس از ثبت نام بتوانید برای او برنامه مدنظرتون رو آماده کنید</span>
                <input className='phone' placeholder='شماره همراه هنرجو'></input>
                <button className='send' onClick={() => this.send()}>ارسال دعوتنامه</button>
                <button className='back' onClick={() => this.props.parentCloseModal()}>برگشت</button>
                <span className='log--error'>
                    <ReactTyped 
                        strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                        style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                    />
                </span>
            </div>
            {this.state.showLoading && (
                <Loading visible={!this.state.loadingFading} showDialog={this.state.loadingDialogShow} dialog={this.state.loadingDialog}/>
            )}
        </>
        );

    }
}

export default Invite