import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import Cookies from 'universal-cookie';
import axios from 'axios';
import Loading from '../../../Loading/Loading';
import '../../../styles/Cpanel/Trainees/Mytrainees.css';

const { Config } = require('../../../config');

class MyTrainees extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , trainees:[] , traineesInfo:[] , catchData: false , mode: "preview" , currentTraineeId: null , currentTraineeName: ""};
        this.rerender = this.rerender.bind(this);
    }

    rerender() {
        this.setState({catchData: false , trainees:[] , traineesInfo:[] , mode: "preview" , currentTraineeId: null});
    }

    enterTrainee(index) {
        setTimeout(() => {
            window.location.href = "/Cpanel/trainees/mytrainees/trainee?id=" + this.state.trainees[index];
            // this.setState({mode: "trainee" , currentTraineeId: this.state.trainees[index] , currentTraineeName: this.state.traineesInfo[index][0]});
        } , "300");
    }

    get_trainees() {
        let response = [] , traineesId = [] , infos = [];
        axios.get(
            Config.BASE_URL  +"/user/coachtrainees/",
            {   
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
            response = res.data;

            let len = response[0].length;
            for(let i = 0; i < len; ++i) {
                traineesId.push(response[0][i]);
                infos.push([response[1][i] , response[2][i]]);
            }
            this.setState({trainees: traineesId , traineesInfo: infos , catchData: true});
        })
    }

    traineecmp(index) {
        return(
            <div className='trainee' id={"trainee"+index.toString()} onClick={() => this.enterTrainee(index)}>
                <div className='details'>
                    <p className='from'><span className='name'>{this.state.traineesInfo[index][0]}</span></p>
                    {/* <p className='phone'>همراه: <span className='phone'>{this.state.traineesInfo[index][1]}</span></p> */}
                </div>
                <div className='controlbox'>
                    {/* <button className='desk' onClick={() => this.enterTrainee(index)}>جزئیات</button> */}
                    <p className='index'>{(index + 1).toString()}</p>
                </div>
            </div>
        );
    }
    trainees() {
        let list = [];
        let len = this.state.trainees.length;
        for(let i = 0; i < len; i += 2) {
            if(i + 1 < len) {
                list.push(
                    <div className='row'>
                        {this.traineecmp(i)}
                        {this.traineecmp(i + 1)}
                    </div>
                )
            }
            else {
                list.push(
                    <div className='row'>
                        {this.traineecmp(i)}
                    </div>
                )
            }
            
        }
        return list;
    }   

    render() {
        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_trainees();
            }, 500)
            return(
                <div className='mytrainees_page' style={{padding: "0"}}>
                    <Loading visible={true} showDialog={false}/>
                </div>
            );
        }

        return (
            <div className='mytrainees_page'>
                <div className='controlbox'>
                    <button className='back' onClick={() => setTimeout(() => {window.history.back()} , "500")}>&lt;</button>
                    <div className='topic'><span>My Trainees</span></div>
                </div>
                <div className='trainees'>
                    {this.trainees()}
                </div>
            </div>
        );
    }
}

export default MyTrainees