import React from 'react';
import ReactDOM from 'react-dom/client';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Home/Menubar.css'
import logo from '../../files/banners/GYMi/LOGO/Picture1.png'

class Menubar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies()};
    }  

    linkfield() {
        if(this.state.cookies.get('user-token') == null) {
            return(
                <button className='button panel' onClick={() => {window.location.href = "/login"}}>ورود</button>
            );
        }
        else {
            if(this.state.cookies.get('role') == "coach" || this.state.cookies.get('role') == "Coach" || this.state.cookies.get('role') == "Coache") {
                return(
                    <button className='button panel' onClick={() => {window.location.href = "/Cpanel"}}>پنل کاربری</button>
                );
            }
            else {
                return(
                    <button className='button panel' onClick={() => {window.location.href = "/Tpanel"}}>پنل کاربری</button>
                );
            }
        }
    }

    render() {
        return (
            <div className = "menubar">
                <div className='logo'>
                    <img src={logo}></img>
                    <span>GymiAi</span>
                </div>
                {this.linkfield()}
            </div>
        );
    }
}

export default Menubar;