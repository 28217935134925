import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import Loading from '../../Loading/Loading';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Config } from '../../config';
import Menubar from '../Menubar';
import '../../styles/Home/Packages/Packages.css'
import MyAlert from '../../Alert/Alert';

import buyIcon from '../../../files/icons/buy.png'
import updateIcon from '../../../files/icons/update.png'

class Packages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , showAlert: false , alertFading: false , alertText: ""};
    }  
    handleShowAlert = (txt) => {
        this.setState({ showAlert: true, alertFading: false , alertText: txt});
    };
    handleCloseAlert = () => {
        this.setState({ alertFading: true });
        setTimeout(() => {
            this.setState({ showAlert: false, alertFading: false , alertText: ""});
        }, 500); // Match this duration with the CSS transition duration
    };

    render() {
        return (
            <div className='packages'>
                <span className='topic'>برای استفاده از خدمات Mentor در Gymi و مدیریت هنرجوهای خود همین حالا بسته تهیه کنید</span>
                <div className='box a' onClick={() => {setTimeout(() => {window.location.href = "/packages/create"} , "350")}}>
                    <img src={buyIcon}></img>
                    <span>ساخت بسته جدید</span>
                </div>
                <div className='box b' onClick={() => {setTimeout(() => {window.location.href = "/packages/update"} , "350")}}>
                    <img src={updateIcon}></img>
                    <span>ارتقا بسته فعلی</span>
                </div>
            </div>
        );
    }
}

export default Packages;