import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Loading from '../../../../Loading/Loading';

import '../../../../styles/Cpanel/Diet.css'
import '../../../../styles/Cpanel/Diet_v2.css'
import Cookies from 'universal-cookie';
import logo from '../../../../../files/banners/GYMi/LOGO/Picture3.png'

const { Config } = require('../../../../config');

class Diet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , lastId: null , dietName: "" , imgurl: null , coachName: "" , senario: "" , traineeName: "" , summary: "" , traineeInfo: "" , daysTitle: [] , daysMeals: [] , catchData: false}
        this.firstpage = this.firstpage.bind(this);
        this.get_diet_data = this.get_diet_data.bind(this);
        this.otherpage = this.otherpage.bind(this);
        this.mealhandler = this.mealhandler.bind(this);
        this.handlediet = this.handlediet.bind(this);
    }

    get_diet_data() {
        let response , name , days , coachname , sen , traineename , summ , traineeinfo , daystitle = [] , daysmeals = [];
        axios.get(
            Config.BASE_URL + "/diets/diet/"+this.props.id,
            {   
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            response = res.data;
            console.log(response);

            name = response.name;
            days = response.days;
            coachname = response.coach;
            sen = response.senario;
            summ = response.summary;
            traineename = response.trainee_name;
            traineeinfo = response.trainee_info;
            for(let i = 0; i < days.length; i = i + 1) {
                daystitle.push(days[i].title);
                daysmeals.push(days[i].meals[0]);
            }
            this.setState({dietName: name , coachName: coachname , senario:sen , summary:summ , traineeName:traineename , traineeInfo: traineeinfo , daysTitle: daystitle , daysMeals: daysmeals , catchData: true});
        })
    }

    getProfileImage() {
        axios.get(
            Config.BASE_URL + "/user/imageurl",
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.setState({imgurl: res.data.url})
            };
        }).catch(error => {
            console.log(error);
        });
    }

    mealhandler(day , index) {
        let topmm = "";
        let nindex = index % 2;
        topmm = (nindex == 0 ? "30mm" : "154mm");

        let ingredients = this.state.daysMeals[day][index].ingredients.split('،');
        let len = ingredients.length;
        let ingredientsItems = [];
        for (let i = 0; i < len; i = i + 1) {
            ingredientsItems.push(<div className="ingredients--item"><span className="text">{ingredients[i].trim()}</span></div>);
        }
        return (
            <div className={"mealbox " + index.toString()} style={{top: topmm}}>
                <div className="index"><span>{this.state.daysMeals[day][index].title}</span></div>
                <div className="meal">
                    <div className="meal--name"><span className="text">{this.state.daysMeals[day][index].food_name}</span></div>
                    <div className="meal--info">
                        <div className="headers">
                            <div className="ingredients"><span className="text">مواد مورد نیاز</span></div>
                            <div className="recip"><span className="text">طرز تهیه</span></div>
                        </div>
                        <div className="info">
                            <div className="ingredients">
                                {ingredientsItems}
                            </div>
                            <div className="recip"><span className="text">{this.state.daysMeals[day][index].recip}</span></div>
                        </div>
                    </div>
                    <div className="meal--analysis">
                        <div className="calories"><span className="tag">کالری:</span><span className="text">{this.state.daysMeals[day][index].calories}</span></div>
                        <div className="protein"><span className="tag">پروتیین:</span><span className="text">{this.state.daysMeals[day][index].protein}</span></div>
                        <div className="carbo"><span className="tag">کربوهیدرات:</span><span className="text">{this.state.daysMeals[day][index].carbohydrate}</span></div>
                        <div className="fat"><span className="tag">چربی:</span><span className="text">{this.state.daysMeals[day][index].fat}</span></div>
                    </div>
                </div>
            </div>
        );
    }
    firstpage() {
        // let meals = [];
        // if(this.state.dietMeals.length > 0) {
        //     meals.push(this.mealhandler(0));
        // }
        // if(this.state.dietMeals.length > 1) {
        //     meals.push(this.mealhandler(1));
        // }
        return(
            <div className='pdf--page' style={{top: "0mm"}}>
                {/* <img className='pagecover' src={daypageCover}></img> */}

                <div className="diet--info">
                    <div className="diet--name"><span className="text">{this.state.dietName}</span></div>
                    <div className="coach--name"><span className="text">{this.state.coachName}</span></div>
                    <div className="coach--sign"><img src={this.state.imgurl}></img></div>
                    <div className='details'>
                        <div className="user--name"><span className="text">هنرجو: {this.state.traineeName}</span></div>
                        <div className="meals--count"><span className="text">تعداد روز: {this.state.daysMeals.length}</span></div>
                        <div className="date--created"><span className="text">تاریخ ثبت</span></div>
                    </div>
                </div>
                <div className='diet--ad'>
                    <div className='web'><span>Gymiai.com</span></div>
                    <div className='logo'><img src={logo}></img></div>
                </div>

                {/* {meals} */}
            </div>
        );
    }
    otherpage(p , day , index , color) {
        let topmm = p * 297 + p * 1 + 1;
        topmm = topmm.toString() + "mm";
        // console.log(topmm);
        let meals = this.state.daysMeals[day];
        let res = [];
        if(index < meals.length) {
            res.push(this.mealhandler(day , index));
        }
        if(index + 1 < meals.length) {
            res.push(this.mealhandler(day , index + 1));
        }
        return (
            <div className={'pdf--page ' + p.toString()} style={{top: topmm}}>
                {/* <img src={daypageCover}></img> */}
                <div className='pdf--page day'>
                    <span>{this.state.daysTitle[day]}</span>
                </div>
                {res}
            </div>
        );
    }

    handlediet() {
        let diet = [];
        let pagenumber = 0;
        if(this.state.senario == "Mentor") {
            diet.push(this.firstpage()); 
            pagenumber = 1;
        }
        
        for (let i = 0; i < this.state.daysMeals.length; i = i + 1) {
            for (let j = 0; j < this.state.daysMeals[i].length; j = j + 2) {
                diet.push(this.otherpage(pagenumber , i , j , "rgb(255,255,255)"));
                pagenumber = pagenumber + 1;
            }
        }

        return diet;
    }

    render() {
        // if(this.state.lastId == null || this.state.lastId != this.props.id) {
        //     this.setState({lastId: this.props.id , catchData: false , streamDialog: null , streamLoading: false , messages:[]});
        // }
        if(this.props.id == null) {
            return;
        }
        if(this.state.lastId == null || this.props.id != this.state.lastId) {
            this.setState({lastId: this.props.id , catchData: false, dietName: "" , dietMeals: []});
            return;
        }
        if(this.state.catchData == false) {
            setTimeout(() => {
                this.getProfileImage();
                this.get_diet_data();
            }, 500)
            return(
                <Loading visible={true} showDialog={false}/>
            );
        }

        return (
            <div className='diet'>
                <div className="pdf--body" id="pdf--body">
                    {this.handlediet()}
                </div>
            </div>
        );
    }
}

export default Diet