import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';
import DietIcon from '../../../../files/icons/salad.gif'
import WorkoutIcon from '../../../../files/icons/weight.gif'
import SupportIcon from '../../../../files/icons/support.png'

const { Config } = require('../../../config');


class Gymi extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies() , gymiMode: null};
        // this.rerender = this.rerender.bind(this);
    }


    render() {
        return (
            <div className='gymi' style={{padding:"0"}}>
                <span className='topic'>Gymi</span>
                <div className='mode diet' onClick={() => {window.location.href = "Cpanel/gymi/diet"}}>
                    <img src={DietIcon}></img>
                    <span>تغذیه</span>
                </div>
                <div className='mode workout' onClick={() => {window.location.href = "Cpanel/gymi/workout"}}>
                    <img src={WorkoutIcon}></img>
                    <span>تمرین</span>
                </div>
                <div className='mode support'onClick={() => {window.location.href = "Cpanel/gymi/support"}}>
                    <img src={SupportIcon}></img>
                    <span>راهنما</span>
                </div>
            </div>
        );
    }
}

export default Gymi