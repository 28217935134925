import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import Cookies from 'universal-cookie';
import TDiets from './Diet/TDiets';


class TPlans extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies() , plansMode: null};
        this.rerender = this.rerender.bind(this);
    }

    rerender() {
        this.setState({cookies: new Cookies() , plansMode: null});
    }

    setAiMode(mode) {
        setTimeout(() => {
            this.setState({plansMode: mode})
        }, "500");
    }


    render() {
        if(this.state.plansMode == null) {
            return (
                <div className='plans' style={{padding:"0"}}>
                    <button className='mode diet' onClick={() => {setTimeout(() => {window.location.href = "/Tpanel/plans/diet"} , "500")}}>{"تغذیه"}</button>
                    <button className='mode workout' onClick={() => {setTimeout(() => {window.location.href = "/Tpanel/plans/workout"} , "500")}}>{"تمرین"}</button>
                </div>
            );
        }
        if(this.state.plansMode == "diet") {
            return (
                <div className='plans' style={{padding:"0"}}>
                    <TDiets parentRefresh = {this.rerender}/>
                </div>
            );
        }
        if(this.state.plansMode == "workout") {
            setTimeout(() => {
                this.setState({plansMode: null});
            }, "5000")
            return (
                <div className='plans'>
                     <div className='commingsoon'>
                        <span>
                            <ReactTyped 
                                strings={["به زودی"]} typeSpeed={100} showCursor={false} startDelay= {1000} backSpeed={100} loop
                                // style={{whiteSpace: 'pre-line' , color: props.color}}
                            />
                        </span>
                    </div>
                </div>
            );
        }
    }
}

export default TPlans