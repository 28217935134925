import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Wallet.css'
import '../../../styles/Cpanel/Wallet_v2.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';

import moment from 'moment-jalaali';



class Wallet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , mode: 'wallet' , catch_data: false , wallet_balance: 0 , active: null , active_at: null , 
            active_package_name:null , offer_days: null, date_history: null , package_history: null, duration_by_day: null
        };

        this.setMode = this.setMode.bind(this);
        this.refresh = this.refresh.bind(this);
    }
    
    refresh() {
        setTimeout(() => {
            this.setState({mode: 'wallet'});
        }, "1000");
    }
    setMode(s) {
        setTimeout(() => {
            this.setState({mode: s});
        }, "1000");
    }

    get_data() {
        this.setState({catch_data: true});
        axios.get(
            Config.BASE_URL + "/payment/userwallet/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res.data);
            this.setState({catchedData: true , wallet_balance: res.data.size , active: res.data.activated , active_at: res.data.activated_at,
                active_package_name: res.data.active_package_name , offer_days: res.data.offer_days , date_history: res.data.date_history , 
                package_history:res.data.package_history, duration_by_day: res.data.duration_by_day
            });
        }).catch(error => {
            console.log(error);
        });
    }

    calc(balance) {

        let tmp = balance.toString();
        let res = "";
        for (let i = 0; i < tmp.length; ++i) {
            if(i > 0 && i % 3 == 0) {
                res += ",";
            }
            res += tmp[tmp.length - 1 - i];
        }
        return res.split('').reverse().join('');
    }

    package_box() {
        if(this.state.active == true) {
            let persianDate = moment(this.state.active_at, 'YYYY-MM-DD HH:mm:ss').format('jYYYY/jMM/jDD');
            // persianDate.split(" ");
            // persianDate = persianDate[0];
            return(
                <div className='package'>
                    <div className='packages--topic'>
                        <span className='txt'>بسته فعال</span>
                    </div>
                    <div className='packages--info'>
                        <div className='namebox'>
                            <p className='name'>نام بسته: <span className='txt'>{this.state.active_package_name}</span></p>
                        </div>
                        <div className='datebox'>
                            <p className='date'>شروع فعالسازی: <span className='txt'>{persianDate}</span></p>
                        </div>
                        <div className='durationbox'>
                            <p className='duration'>اعتبار: <span className='txt'>{this.state.duration_by_day}</span> روز</p>
                        </div>
                        <div className='offerbox'>
                            <p className='offer'>روز هدیه: <span className='txt'>{this.state.offer_days}</span></p>
                        </div>
                        <div className='controlbox'>
                            <button className='history'>تاریخچه</button>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return(
                <div className='package'>
                    <div className='packages--topic'>
                        <span className='txt'>بسته فعال</span>
                    </div>
                    <div className='packages--info'>
                        <div className='namebox'>
                            <p className='name' style={{color:"rgb(100,0,0)"}}>در حال حاضر بسته ای برای شما فعال نمیباشد!</p>
                        </div>
                        <div className='datebox' style={{visibility:"hidden"}}>
                            <p className='date'>شروع فعالسازی: <span className='txt'>{this.state.activated_at}</span></p>
                        </div>
                        <div className='durationbox' style={{visibility:"hidden"}}>
                            <p className='duration'>اعتبار: <span className='txt'>{this.state.duration_by_day}</span></p>
                        </div>
                        <div className='offerbox' style={{visibility:"hidden"}}>
                            <p className='offer'>روز هدیه: <span className='txt'>{this.state.offer_days}</span></p>
                        </div>
                        <div className='controlbox' style={{visibility:"visible"}}>
                            <button className='history'>تاریخچه</button>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        if(this.state.catch_data == false) {
            this.get_data();
        }
        return(
            <div className='wallet'>
                <div className='wallet--topic'>
                    <span className='txt'>کیف پول</span>
                </div>
                <div className='wallet--size'>
                    <span className='balance--topic'>موجودی</span>
                    <p className='balance'><span className='amount'>{this.calc(this.state.wallet_balance)}</span>تومان</p>
                    <div className='controlbox'>
                        <button className='deposite--button' onClick={() => {window.location.href = "/wallet/deposite?balance="+this.state.wallet_balance}}>افزایش  +</button>
                    </div>
                </div>
                {this.package_box()}
            </div>
        );
    }
}

export default Wallet