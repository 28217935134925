import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import '../styles/Agreement.css'

const { Config } = require('../config');


class Agreement extends React.Component { 
    constructor(props) {
        super(props)
    } 

    render() {
        return(
            <div className='agreement' id='agreement'>
                <span className='text'>{this.props.dialog}</span>
                <button className='agree' onClick={() => {setTimeout(() => {this.props.agree()},"500")}}>اطمینان دارم</button>
                <button className='back' onClick={() => {setTimeout(() => {this.props.back()},"500")}}>برگشت</button>
            </div>
        );
    }
}

export default Agreement