import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import Cookies from 'universal-cookie';
import Requests from './Requests';
import MyTrainees from './MyTrainees';

import ReqIcon from '../../../../files/icons/requests.png'
import TraineesIcon from '../../../../files/icons/mytrainees.png'

class Trainees extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies() , mode: null};
    }


    render() {
        return (
            <div className='trainees' style={{padding:"0"}}>
                <span className='topic'>میز‌کار</span>
                <div className='mode requests' onClick={() => {setTimeout(() => {window.location.href = "/Cpanel/trainees/requests"} , "300")}}>
                    <img src={ReqIcon}></img>
                    <span>درخواست‌های من</span>
                </div>
                <div className='mode mytrainees' onClick={() => {setTimeout(() => {window.location.href = "/Cpanel/trainees/mytrainees"} , "300")}}>
                    <img src={TraineesIcon}></img>
                    <span>هنرجوهای‌ من</span>
                </div>
            </div>
        );
    }
}

export default Trainees