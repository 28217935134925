import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import logo from '../../files/banners/GYMi/LOGO/Picture1.png'
import '../styles/Loading/Loading.css'
import '../styles/Loading/Loading_v2.css'

class Loading extends React.Component { 
    constructor(props) {
        super(props)
    }

    render() {
        let displayLogo = (!this.props.showDialog ? "flex" : "none");
        let displayDialog = (this.props.showDialog ? "flex" : "none");
        return (
            <div className={`loading ${this.props.visible ? 'fade-in' : 'fade-out'}`} id='loading--page'>

                <img className='loading--logo' id='loading--logo' src={logo} style={{display:displayLogo}}></img>
                <p className='loading--dialog' id='loading--success' style={{color:"rgba(52, 152, 249, 1)" , display:displayDialog}}>{this.props.dialog}</p>

            </div>
        );
    }
}

export default Loading