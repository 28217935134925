import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Profile/Coachingcode.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';
import MyAlert from '../../../Alert/Alert';


class CoachingCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , showAlert: false , alertFading: false , alertText: "" , catchedData: false , activation_code: ""};

    }
    handleShowAlert = (txt) => {
        this.setState({ showAlert: true, alertFading: false , alertText: txt});
        setTimeout(() => {
            this.handleCloseAlert();
        } , "2000")
    };
    handleCloseAlert = () => {
        this.setState({ alertFading: true });
        setTimeout(() => {
            this.setState({ showAlert: false, alertFading: false , alertText: ""});
        }, 500); // Match this duration with the CSS transition duration
    };

    getData() {
        axios.get(
            Config.BASE_URL + "/user/cprofile/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            this.setState({catchedData: true , activation_code: res.data.activation_code});
        }).catch(error => {
            console.log(error);
        });
    }

    send() {
        let Phone = document.getElementsByClassName("phone")[0].value;

        axios.post(
            Config.BASE_URL + "/user/invitetrainee/",
            {
                phone_number: Phone,
            },
            {   
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            let response = res.data;
            if (response.status == "ok") {
                this.handleShowAlert("دعوت نامه با موفقیت ارسال شد")
            }
        }).catch(error => {
            console.log(error);
            if(error.response.data.error == "invalid phone") {
                this.handleShowAlert("شماره همراه معتبر نمیباشد");
            }
            else if(error.response.data.error == "phone already signedup") {
                this.handleShowAlert("هنرجو قبلا با این شماره ثبت نام کرده است");
            }
            else {
                this.handleShowAlert("خطایی پیش آمده دوباره تلاش کنید!");
            }
        });
    }

    render() {
        if(this.state.catchedData == false) {
            setTimeout(() => {
                this.getData();
            }, "500");
            return (
                <Loading visible={true} showDialog={false}/>
            );
        }
        else {

            return (
                <div className='activation'>
                    <span className='topic'>کد اختصاصی مربیگری شما:</span>
                    <div className='activation--box'>
                        <span className='activation--code'>{this.state.activation_code}</span>
                    </div>
                    <span className='share'>هنرجویان شما با این کد میتوانند ثبت نام کنند آنها را دعوت کنید</span>
                    <div className='activation--sharing'>
                        <input className='phone' placeholder='تلفن همراه هنرجو'></input>
                        <button className='send' onClick={() => this.send()}>دعوت</button>
                    </div>
                    {this.state.showAlert && (
                        <MyAlert
                            text={this.state.alertText}
                            close={this.handleCloseAlert} 
                            visible={!this.state.alertFading} 
                            color="rgb(50,100,50)"
                            textcolor="rgb(10,10,10)"
                        />
                    )}
                </div>
            );
        }
    }
}

export default CoachingCode