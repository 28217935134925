import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Signup/Signup.css'
import '../styles/Signup/Signup_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture3.png'
import cover from '../../files/homecover.jpg'
import Loading from '../Loading/Loading';
import Menubar from '../Home/Menubar';
import User from '../Cpanel/components/Profile/EditInfo';

const { Config } = require('../config');


class SignupEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , userMode: null};
    }

    setUserMode(mode) {
        let revMode = (mode == "coach" ? "trainee" : "coach");
        // document.getElementsByClassName("mode " + revMode)[0].style.width = "0%";
        // document.getElementsByClassName("mode " + revMode)[0].style.display = "none";
        // document.getElementsByClassName("mode " + mode)[0].style.width = "100%";
        // document.getElementsByClassName("mode " + mode)[0].style.color = "rgb(0,60,0)";
        // document.getElementsByClassName("mode " + mode)[0].style.borderColor = "rgb(185, 55, 20)";
        // document.getElementsByClassName("mode " + mode)[0].style.borderRadius = "20px";
        setTimeout(() => {
            if(mode == "coach")
                window.location.href = "/csignup"
            if(mode == "trainee")
                window.location.href = "/tsignup"
        }, "800");
    }

    render() {
        if(this.state.cookies.get('user-token') != null) {
            window.location.href = '/';
            return;
        }
        

        return(
            <div className='signupentry'>
                <Menubar />
                <div className='form mode'>
                    <div className='form--topic'>
                        <p className='welcome'>به<span>GymiAi</span>خوش آمدید!</p>
                        <p className='topic role'>نقش خود را انتخاب کنید</p>
                    </div>
                    <div className='roles'>
                        <button className='mode coach' onClick={() => this.setUserMode("coach")}>{"مربی"}</button>
                        <button className='mode trainee' onClick={() => this.setUserMode("trainee")}>{"هنرجو"}</button>
                    </div>
                </div>    
            </div>
        );
    }
}

export default SignupEntry;