import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Profile/Userinterface.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';
import ImageUploader from './ImageUploader';


class UserInterface extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , catchedData:false , imgURL: "" , first_name: "" , last_name: "" , username: "" , countTrainees: 0 , countPlans: 0};
    }

    
    getData() {
        axios.get(
            Config.BASE_URL + "/user/cprofile/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            this.setState({catchedData: true , imgURL: res.data.imgurl , username: res.data.username , first_name: res.data.first_name , last_name: res.data.last_name,
                countTrainees: res.data.countT , countPlans: res.data.countP
            });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        if(this.state.catchedData == false) {
            setTimeout(() => {
                this.getData();
            }, "50");
        }
        
        return(
            <div className='userinterface'>
                <div className='image'>
                    {this.state.imgURL == null ? <span>profile image</span>:<img src={this.state.imgURL}></img>}
                </div>
                <div className='info'>
                    <span className='username'>{this.state.username}</span>
                    <span className='name'>{this.state.first_name + " " + this.state.last_name}</span>
                    <p className='trainee'><span>{this.state.countTrainees}</span> هنرجو</p>
                    <p className='plan'><span>{this.state.countPlans}</span> برنامه</p>
                </div>
            </div>
        );
    }
}

export default UserInterface