import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Profile/Profile.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';
import User from './EditInfo';
import Sign from './Sign';
import Activation from './CoachingCode';
import UserInterface from './UserInterface';
import MyAlert from '../../../Alert/Alert';


class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , catchedData: false , is_partner: false , showAlert: false , alertFading: false , alertText: "" , mode: null , checked: false};

        this.setMode = this.setMode.bind(this);
    }
    handleShowAlert = (txt) => {
        this.setState({ showAlert: true, alertFading: false , alertText: txt});
    };
    handleCloseAlert = () => {
        this.setState({ alertFading: true });
        setTimeout(() => {
            this.setState({ showAlert: false, alertFading: false , alertText: ""});
        }, 500); // Match this duration with the CSS transition duration
    };


    getData() {
        axios.get(
            Config.BASE_URL + "/user/cprofile/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            this.setState({catchedData: true , checked : res.data.is_partner});
        }).catch(error => {
            console.log(error);
        });
    }

    sendcheck(checker) {
        axios.post(
            Config.BASE_URL + "/user/partnersshow/",
            {
                checker: checker
            },
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            let dialog = (res.data.status == true ? "به همکاران جیمی اضافه شدید!" : "از همکاران جیمی حذف شدید!")
            this.handleShowAlert(dialog);
            setTimeout(() => {
                this.handleCloseAlert();
            } , "2000")
        }).catch(error => {
            console.log(error);
        });
    }

    setMode(s) {
        setTimeout(() => {window.location.href = "/Cpanel/profile/" + s} , "500");
    }

    checkhandler() {
        let val = !this.state.checked;
        this.setState({checked: val});
        setTimeout(() => {this.sendcheck(val);} , "500")
    }

    render() {
        if(this.state.catchedData == false) {
            this.getData();
        }
        return (
            <div className='profile'>
                <div className='interface'>
                    <UserInterface />
                </div>
                <div className='checkbox'>
                    <div className='box'>
                        <label>
                            <input type="checkbox" checked={this.state.checked} onChange={() => this.checkhandler()}/>
                            من را در قسمت همکاران جیمی نشان بده
                        </label>
                    </div>
                </div>
                <div className='controlbox'>
                    <div className='box 1'>
                        <button className='invitetogymi' 
                            onClick={() => this.setMode("invitetogymi")}
                            style={{backgroundImage:'url(\'https://gymiai.com/files/icons/invite.png\')'}}>
                        </button>
                        <span className='txt'>دعوت به جیمی</span>
                    </div>
                    <div className='box 2'>
                        <button className='coachingcode' 
                            onClick={() => this.setMode("coachingcode")}
                            style={{backgroundImage:'url(\'https://gymiai.com/files/icons/coachcode.png\')'}}>
                        </button>
                        <span className='txt'>کد مربیگری من</span>
                    </div>
                    <div className='box 3'>
                        <button className='editinfo' 
                            onClick={() => this.setMode("editinfo")}
                            style={{backgroundImage:'url(\'https://gymiai.com/files/icons/info.png\')'}}>
                        </button>
                        <span className='txt'>ویرایش حساب</span>
                    </div>
                </div>
                {this.state.showAlert && (
                    <MyAlert
                        text={this.state.alertText}
                        close={this.handleCloseAlert} 
                        visible={!this.state.alertFading} 
                        color="rgb(20,20,20,0.9)"
                        textcolor="white"
                    />
                )}
            </div>
        );
    }
}

export default Profile