import React from 'react';
import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import ReactDOM from 'react-dom';

import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Cpanel from './pages/Cpanel/Cpanel';
import Tpanel from './pages/Tpanel/Tpanel';
import DietRegisterWithParams from './pages/DietRegister/WithParams';
import ForgotpasswordWithParams from './pages/Forgot/WithParams';
import Forgotpassword from './pages/Forgot/Forgotpassword';
import Cverification from './pages/Verification/Cverification';
import Tverification from './pages/Verification/Tverification';
import Csignup from './pages/Signup/Csignup';
import Tsignup from './pages/Signup/Tsignup';
import SignupEntry from './pages/Signup/SignupEntry';
import Deposite from './pages/Cpanel/components/Wallet/Deposite';
import DepositeVerify from './pages/Cpanel/components/Wallet/DepositeVerify';
import Packages from './pages/Home/Packages/Packages';
import GymiDiet from './pages/Cpanel/components/Gymi/GymiDiet';
import GymiSupport from './pages/Cpanel/components/Gymi/GymiSupport';
import GymiWorkout from './pages/Cpanel/components/Gymi/GymiWorkout';
import DietsDemo from './pages/Cpanel/components/Plans/Diet/DietsDemo';
import DietsMentor from './pages/Cpanel/components/Plans/Diet/DietsMentor';
import Diets from './pages/Cpanel/components/Plans/Diet/Diets';
import MyTrainees from './pages/Cpanel/components/Trainees/MyTrainees';
import TraineeDesk from './pages/Cpanel/components/Trainees/TraineeDesk';
import Requests from './pages/Cpanel/components/Trainees/Requests';
import TDiets from './pages/Tpanel/components/Plans/Diet/TDiets';

import Cookies from 'universal-cookie';
import CoachingCode from './pages/Cpanel/components/Profile/CoachingCode';
import EditInfo from './pages/Cpanel/components/Profile/EditInfo';
import CreatePackage from './pages/Home/Packages/CreatePackage';

function App() {

    const isAuthenticated = () => {
        let cookies = new Cookies();
        return !!cookies.get('user-token'); 
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path = "/" element = {<Home  />}/>

                <Route path = "/signupentry" element = {<SignupEntry />}/>
                <Route path = "/Csignup" element = {<Csignup />}/>
                <Route path = "/Tsignup" element = {<Tsignup />}/>
                <Route path = "/login" element = {<Login />}/>
                <Route path = "/Cverify" element = {<Cverification />}/>
                <Route path = "/Tverify" element = {<Tverification />}/>
                
                <Route path = "/Cpanel" element = {isAuthenticated() ? <Cpanel /> : <Navigate to="/" />}/>
                <Route path = "/Tpanel" element = {isAuthenticated() ? <Tpanel /> : <Navigate to="/" />}/>
                <Route path = "/forgot" element = {<Forgotpassword token={null}/>}/>
                <Route path = "/dietregister/:id" element = {<DietRegisterWithParams />}/>
                <Route path = "/forgotpassword" element = {<Forgotpassword />}/>
                <Route path = "/forgotpassword/:token" element = {<ForgotpasswordWithParams />}/>

                <Route path = "/wallet/deposite" element = {isAuthenticated() ? <Deposite /> : <Navigate to="/" />}/>
                <Route path = "/deposite" element = {<DepositeVerify />}/>
                <Route path = "/packages" element = {<Packages />}/>
                <Route path = "/packages/create" element = {<CreatePackage />}/>

                <Route path = "Cpanel/gymi/diet" element = {isAuthenticated() ? <GymiDiet /> : <Navigate to="/" />}/>
                <Route path = "Cpanel/gymi/workout" element = {isAuthenticated() ? <GymiWorkout /> : <Navigate to="/" />}/>
                <Route path = "Cpanel/gymi/support" element = {isAuthenticated() ? <GymiSupport /> : <Navigate to="/" />}/>

                <Route path = "Cpanel/plans/diet" element = {isAuthenticated() ? <Diets /> : <Navigate to="/" />}/>
                <Route path = "Cpanel/plans/diet/demo" element = {isAuthenticated() ? <DietsDemo /> : <Navigate to="/" />}/>
                <Route path = "Cpanel/plans/diet/mentor" element = {isAuthenticated() ? <DietsMentor /> : <Navigate to="/" />}/>

                <Route path = "Cpanel/trainees/requests" element = {isAuthenticated() ? <Requests /> : <Navigate to="/" />}/>
                <Route path = "Cpanel/trainees/mytrainees" element = {isAuthenticated() ? <MyTrainees /> : <Navigate to="/" />}/>
                <Route path = "Cpanel/trainees/mytrainees/trainee" element = {isAuthenticated() ? <TraineeDesk /> : <Navigate to="/" />}/>

                <Route path = "Tpanel/plans/diet" element = {isAuthenticated() ? <TDiets /> : <Navigate to="/" />}/>
                {/* <Route path = "Tpanel/plans/workouts" element = {<MyTrainees />}/> */}
                
                <Route path = "Cpanel/profile/coachingcode" element = {isAuthenticated() ? <CoachingCode /> : <Navigate to="/" />}/>
                <Route path = "Cpanel/profile/editinfo" element = {isAuthenticated() ? <EditInfo /> : <Navigate to="/" />}/>
                <Route path="*" element={<Navigate to="/" />} /> 
            </Routes>
        </BrowserRouter>
    );
}

export default App;