import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import Loading from '../../Loading/Loading';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Config } from '../../config';
import Menubar from '../Menubar';
import '../../styles/Home/Packages/Createpackage.css'
import MyAlert from '../../Alert/Alert';

import logo1 from '../../../files/icons/logo.png'
import logo2 from '../../../files/icons/logo2.png'
import { duration } from 'moment-jalaali';
import Agreement from '../../Agreement/Agreement';

class CreatePackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , showAlert: false , alertFading: false , alertText: "" , catch_data: false , price: 0,
            duration: 7 , trainees: 5 , plans: 2 , name : "" , initialize: false
        };
        
        this.activate = this.activate.bind(this);
    }  
    handleShowAlert = (txt) => {
        this.setState({ showAlert: true, alertFading: false , alertText: txt});
        setTimeout(() => {
            this.handleCloseAlert();
        } , "2000")
    };
    handleCloseAlert = () => {
        this.setState({ alertFading: true });
        setTimeout(() => {
            this.setState({ showAlert: false, alertFading: false , alertText: ""});
        }, 500); // Match this duration with the CSS transition duration
    };

    get_price() {
        this.setState({catch_data:true});
        axios.post(
            Config.BASE_URL + "/payment/calcpackage/",
            {
                mode: "Diet",
                category: "Coach",
                duration: this.state.duration,
                trainees: this.state.trainees,
                plans: this.state.plans,
            },
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            this.setState({price: res.data.price , name:res.data.name});
            
            setTimeout(() => {
                let elem = document.getElementsByClassName("price")[0].getElementsByTagName('span')[0];
                elem.style.opacity = "1";
            }, "100");
            
        }).catch(error => {
            console.log(error);
            // if(error.response.data.error == "not enough wallet size") {
            //     this.handleShowAlert("موجودی کیف پول شما کافی نمیباشد");
            //     setTimeout(() => {
            //         this.handleCloseAlert();
            //     } , "2000")
            // }
            // else if(error.response.data.error == "already have package") {
            //     this.handleShowAlert("در حال حاضر بسته دیگری برای شما فعال میباشد");
            //     setTimeout(() => {
            //         this.handleCloseAlert();
            //     } , "2000")
            // }
            // else {
            //     this.handleShowAlert("حساب شما به عنوان مربی درنظر گرفته نشده");
            //     setTimeout(() => {
            //         this.handleCloseAlert();
            //     } , "2000")
            // }
        });
    }

    send_activation() {
        axios.post(
            Config.BASE_URL + "/payment/buypackage/",
            {
                mode: "Diet",
                category: "Coach",
                duration: this.state.duration,
                trainees: this.state.trainees,
                plans: this.state.plans,
            },
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            this.handleShowAlert("بسته با موفقیت فعال شد");
        }).catch(error => {
            console.log(error);
            let diag = error.response.data.error 
            if(diag == "not enough balance") {
                this.handleShowAlert("موجودی کیف پول شما کافی نمیباشد");
            }
            else if(diag == "already have package") {
                this.handleShowAlert("در حال حاضر بسته دیگری برای شما فعال میباشد");
            }
            else if(diag == "user not a coach") {
                this.handleShowAlert("حساب شما به عنوان مربی درنظر گرفته نشده");
            }
            else if(diag == "trainees limit") {
                this.handleShowAlert("حداکثر هنرجو نباید از هنرجویان فعلی شمما کمتر باشد");
            }
            else {
                this.handleShowAlert("خطایی پیش آمده دوباره تلاش کنید");
            }
        });
    }

    activate() {
        this.agreement_down();
        if(this.state.cookies.get("user-token") != null) {
            this.send_activation();
        }
        else {
            this.handleShowAlert("ابتدا ثبت نام کرده و یا وارد حساب خود شوید");
        }
    }

    initialize() {
        let ids = ["sel_duration" , "sel_trainee" , "sel_plan"];
        let vals = [7 , 5 , 2];

        for (let i = 0; i < 3; ++i) {
            this.boxclicked(ids[i] , 0 , vals[i]);
        }
        this.setState({initialize:true});
    }

    select(elem) {
        elem.style.backgroundColor = "rgb(235,125,90)";
        elem.style.boxShadow = "0px 2px 20px 2px rgb(0,50,0)";
    }
    clear(elem) {
        elem.style.backgroundColor = "rgb(255,255,255)";
        elem.style.boxShadow = "0 0 0 rgb(185,55,20)";
    }

    modeclicked(id) {
        let elem = document.getElementById(id);
        if(id="gymidietmode") {
            if(this.state.modediet == true) {
                elem.style.opacity = "0.5";
                elem.style.removeProperty("background-color");
                this.setState({modediet: false});
            }
            else {
                elem.style.opacity = "0.7";
                elem.style.backgroundColor = "rgb(200,200,200)";
                this.setState({modediet: true});
            }
        }
        else {
            if(this.state.modeworkout == true) {
                elem.style.opacity = "0.5";
                elem.style.removeProperty("background-color");
                this.setState({modeworkout: false});
            }
            else {
                elem.style.opacity = "0.7";
                elem.style.backgroundColor = "rgb(200,200,200)";
                this.setState({modeworkout: true});
            }
        }

    }
    boxclicked(id , i , val) {
        if(id == "sel_duration") {
            this.setState({duration:val , catch_data: false});
        }
        if(id == "sel_trainee") {
            this.setState({trainees:val , catch_data: false});
        }
        if(id == "sel_plan") {
            this.setState({plans:val , catch_data: false});
        }
        let elem = document.getElementById(id);
        let boxes = elem.getElementsByClassName("box"); 
        let len = boxes.length;
        for(let j = len - 1; j > i; --j) {
            this.clear(boxes[j])
        }
        for(let j = 0; j <= i; ++j) {
            this.select(boxes[j]);
        }
        let number = elem.getElementsByClassName("value")[0].getElementsByTagName("span")[0];
        number.innerHTML = val.toString();
    }

    selector(id , s) {
        let len = s.length;
        let list = [];
        for (let i = 0; i < len; i+=1) {
            list.push(
                <div className={'box ' + i.toString()} onClick={() => {this.boxclicked(id , i , s[i])}}></div>
            );
        }
        return (
            <div className='selector' id={id}>
                <div className='boxes'>
                    {list}
                </div>
                <div className='value'>
                    <span className='txt'></span>
                </div>
            </div>
        );  
    }

    calc(balance) {

        let tmp = balance.toString();
        let res = "";
        for (let i = 0; i < tmp.length; ++i) {
            if(i > 0 && i % 3 == 0) {
                res += ",";
            }
            res += tmp[tmp.length - 1 - i];
        }
        return res.split('').reverse().join('');
    }

    agreement_up() {
        document.getElementById('agreement').style.display = "flex";
        setTimeout(() => {
            document.getElementById('agreement').style.opacity = "1";
        },"100");
    }

    agreement_down() {
        document.getElementById('agreement').style.opacity = "0";
        setTimeout(() => {
            document.getElementById('agreement').style.display = "none";
        },"200");
    }

    render() {
        if(this.state.initialize == false){
            setTimeout(() => {
                this.initialize();
            } , "1000");
        }

        if(this.state.catch_data == false) {
            setTimeout(() => {
                let elem = document.getElementsByClassName("price")[0].getElementsByTagName('span')[0];
                elem.style.opacity = "0";
            } , "200")
            this.get_price();
        }
        return(
            <div className='create_package'>
                <span className='topic'>بسته مناسب خود را بسازید</span>
                <div className='create'>

                    <div className='section a'>
                        <span className='duration'>مدت به روز</span>   
                        {this.selector("sel_duration" , [7,15,30,45,60])} 
                    </div>

                    <div className='section b'>
                        <span className='trainee'>سقف هنرجو</span>   
                        {this.selector("sel_trainee" , [5,10,20,30,50])} 
                    </div>

                    <div className='section c'>
                        <span className='plan'>سقف برنامه</span>   
                        {this.selector("sel_plan" , [2,3,4,6,8])} 
                    </div>
                    
                    {/* <div className='section d'>
                        <span className='mode'>Gymi</span>
                        <div className='selector' id='sel_gymi'>
                            <div className='dietmode'>
                                <img id = "gymidietmode" src={logo1} onClick={() => this.modeclicked("gymidietmode")}></img>
                                <span className='txt'>Diet</span>
                            </div>
                            <div className='workoutmode'>
                                <img id = "gymiworkoutmode" src={logo2} onClick={() => this.modeclicked("gymiworkoutmode")}></img>
                                <span className='txt'>Workout</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='activate'>
                    <div className='price'>
                        <p><span>{this.calc(this.state.price)}</span>تومان</p>
                    </div>
                    <button className='active' onClick={() => this.agreement_up()}>فعالسازی</button>
                </div>           
                <Agreement dialog="مطمئنی؟" agree={this.activate} back={this.agreement_down}/>
                {this.state.showAlert && (
                    <MyAlert
                        text={this.state.alertText}
                        close={this.handleCloseAlert} 
                        visible={!this.state.alertFading} 
                        color="rgb(50,100,50)"
                        textcolor="rgb(10,10,10)"
                    />
                )}
            </div>
        );
    }
}

export default CreatePackage;