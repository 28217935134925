import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';
import Loading from '../../../Loading/Loading';
import Chat from './Chat';

import logo from '../../../../files/banners/GYMi/LOGO/logo3.png'
import logo2 from '../../../../files/banners/GYMi/LOGO/logo.png'
import Invite from './Invite';
import '../../../styles/Cpanel/Gymi/Gymidiet.css'

import rightIcon from '../../../../files/icons/arrow-point-to-right.png'
import leftIcon from '../../../../files/icons/left-arrow.png'

const { Config } = require('../../../config');


class GymiDiet extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies() , conversations:[] , catchData: false , conversationsInfo:[] , currentChatId: null , sidebar: true , currentChatIndex: null};
        this.get_conversations_id = this.get_conversations_id.bind(this);
        this.rerender = this.rerender.bind(this);
        this.closeChatModal = this.closeChatModal.bind(this);
    }
    rerender() {
        this.setState({cookies: new Cookies() , conversations:[] , catchData: false , conversationsInfo:[] , currentChatId: null , currentChatType: null});
    }


    chatenter(force , id , type) {
        if(id == this.state.currentChatIndex && force != 2) return;
        if(id == null) return;

        let chat = "chat" + id;
        if(type == "Demo") {
            document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "1";
            document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "rgb(255,255,255)";
            document.getElementById(chat).style.backgroundColor = "rgb(185,55,20,0.8)";
            document.getElementById(chat).style.boxShadow = "0px 0px 10px 0px rgb(0,120,0)";
        }
        else {
            document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "rgb(185,55,20)";
            document.getElementById(chat).style.backgroundColor = "rgb(255,255,255)";
            document.getElementById(chat).style.boxShadow = "0px 0px 5px 0px rgb(0,120,0)";
        }
    }
    chatleave(force , id , type) {
        if(id == this.state.currentChatIndex && force != 2) return;
        if(id == null) return;

        let chat = "chat" + id;
        if(type == "Demo") {
            document.getElementById(chat).getElementsByClassName('chatcover')[0].style.opacity = "0.5";
            document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "black";
            document.getElementById(chat).style.backgroundColor = "rgb(200,200,200)";
            document.getElementById(chat).style.boxShadow = "";
        }
        else {
            document.getElementById(chat).getElementsByClassName('chat--name')[0].style.color = "rgb(100,100,100)";
            document.getElementById(chat).style.backgroundColor = "transparent";
            document.getElementById(chat).style.boxShadow = "";
        }
    }

    get_conversations_id() {
        let response = [] , conversationsId = [] , infos = [];
        axios.get(
            Config.BASE_URL + "/chat/conversations/" + this.state.cookies.get("username"),
            {   
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
            response = res.data;
            let demoId = "" , demoIndex = "";
            for(let i = 0; i < response.length; ++i) {
                conversationsId.push(response[i].id);
                let info = [];
                if(response[i].senario == "Demo") {
                    demoId = response[i].id;
                    demoIndex = i;
                }
                info.push(response[i].senario);
                info.push(response[i].has_plan);
                info.push(response[i].name);
                infos.push(info);
            }
            this.setState({conversations: conversationsId , conversationsInfo: infos , currentChatId: demoId  , currentChatIndex: demoIndex , currentChatType:"Demo" , catchData: true});
            setTimeout(() => {
                this.chatenter(2 , this.state.currentChatIndex , "Demo");
            } , 2000);
        })
    }

    chatcmp(index , type) {
        if(index == -1) {
            return(
                <div className='chat invite' onClick={() => this.openInviteModal()}>
                    <span>+</span>
                </div>
            );
        }
        let info = this.state.conversationsInfo[index];
        return(
            <div className={'chat ' + type} id={"chat"+index.toString()} onClick={() => this.openChatModal(index)} onMouseEnter={() => this.chatenter(1 , index.toString() , type)} onMouseLeave={() => this.chatleave(1 , index.toString() , type)}>
                {type == "Demo" ? <img className='chatcover' src={type == "Demo" ? logo:logo2}></img> : null}
                <p className='chat--name'>{info[2]}</p>
            </div>
        );
    }

    chatdemo() {
        let list = [];
        let len = this.state.conversations.length;
        for(let i = 0; i < len; i += 1) {
            if(this.state.conversationsInfo[i][0] == "Demo") {
                list.push(this.chatcmp(i , "Demo"));
                break;
            }
        }
        return list;
    }

    chatsmentor() {
        let list = [];
        let len = this.state.conversations.length;
        for(let i = 0; i < len; i += 1) {
            if(this.state.conversationsInfo[i][0] == "Mentor") {
                list.push(this.chatcmp(i , "Mentor"));
            }
        }
        list.push(this.chatcmp(-1 , "Invite"));
        return list;
    }
    openChatModal(index) {
        if(this.state.currentChatIndex != null)
            this.chatleave(2 , this.state.currentChatIndex , this.state.conversationsInfo[this.state.currentChatIndex][0])
        this.chatenter(2 , index , this.state.conversationsInfo[index][0]);
        this.setState({currentChatId: this.state.conversations[index] , currentChatIndex: index});
    }
    closeChatModal() {
        window.history.back();
    }
    openInviteModal() {
        document.getElementsByClassName("invite--modal")[0].style.display = "flex";
        document.getElementsByClassName("invite--modal")[0].style.visibility = "visible";

        setTimeout(() => {
            document.getElementsByClassName("modal--invitebox")[0].style.opacity = "1";
        } , "100");
    }
    closeInviteModal() {
        document.getElementsByClassName("modal--invitebox")[0].style.opacity = "0";
        setTimeout(() => {
            document.getElementsByClassName("invite--modal")[0].style.display = "none";
        } , "300");
    }

    sidebarmode() {
        this.setState({sidebar: !this.state.sidebar});
    }

    render() {

        window.onclick = function(event) {
            if (event.target.className ==  "endconv--modal" || event.target.className == "disagree") {
                document.getElementsByClassName("modal--endverify")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("endconv--modal")[0].style.display = "none";
                } , "300");
            }
            if (event.target.className == "delconv--modal" || event.target.className == "disagree") {
                document.getElementsByClassName("modal--delverify")[0].style.opacity = "0";
                setTimeout(() => {
                    document.getElementsByClassName("delconv--modal")[0].style.display = "none";
                } , "300");
            }
        }  

        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_conversations_id();
            }, 500)
            return(
                <Loading visible={true} showDialog={false}/>
            );
        }

        return (
            <div className ='gymi_diet'>
                <div className='chatbar'>
                    <Chat id={this.state.currentChatId} parentRefresh={this.rerender} parentCloseChat={this.closeChatModal}/>
                </div>
                <div className='sidebar' style={{left:(this.state.sidebar ? "0%" : "calc(-60% + 20px)") , boxShadow:(this.state.sidebar ? "0px 0px 20px rgb(185,55,20)" : "0px 0px 3px rgb(185,55,20)")}}>
                    <div className='control'>
                        <img src={this.state.sidebar ? leftIcon: rightIcon} onClick={() => this.sidebarmode()}></img>
                    </div>
                    <div className='chatslist'>
                        <div className='topic'>
                            <span>Gymi | Ai</span>
                        </div>
                        <div className='topic--mentor'>
                            <span>MENTOR chats</span>
                        </div>
                        <div className='mentor'>
                            {this.chatsmentor()}
                        </div>
                        <div className='topic--demo'>
                            <span>Demo chat</span>
                        </div>
                        <div className='demo'>
                            {this.chatdemo()}
                        </div>        
                    </div>
                </div>
                <div className='invite--modal'>
                    <div className='modal--invitebox'>
                        <Invite parentCloseModal={this.closeInviteModal}/>
                    </div>
                </div>
            </div>
        );

    }
}

export default GymiDiet