import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import Loading from '../../../Loading/Loading';
import upicon from '../../../../files/upicon.png'
import icon from '../../../../files/banners/GYMi/LOGO/logo.png'
import Cookies from 'universal-cookie';
import '../../../styles/Cpanel/Gymi/Chat.css'
import { Alert } from '@mui/material';
import MyAlert from '../../../Alert/Alert';

const { Config } = require('../../../config');

let scrollinv = null;

class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , showAlert: false , alertFading: false , alertText: "" , lastId: null , catchData: false , streamDialog: null , streamLoading: false , messages:[],
            loadingDialog: "", showLoading: false , loadingFading: false , loadingDialogShow: false
        }
        this.update_display = this.update_display.bind(this);
        this.shortcut = this.shortcut.bind(this);
        this.send_message = this.send_message.bind(this);
        this.chatHistory = this.chatHistory.bind(this);
        this.streamChat = this.streamChat.bind(this);
        this.get_all_messages = this.get_all_messages.bind(this);
        this.endConversation = this.endConversation.bind(this);
        this.delConversation = this.delConversation.bind(this);
    }
    handleShowAlert = (txt) => {
        this.setState({ showAlert: true, alertFading: false , alertText: txt});
    };
    handleCloseAlert = () => {
        this.setState({ alertFading: true });
        setTimeout(() => {
            this.setState({ showAlert: false, alertFading: false , alertText: ""});
            this.props.parentCloseChat()
        }, 500); // Match this duration with the CSS transition duration
    };

    handleShowLoading = (txt) => {
        this.setState({showLoading: true, loadingFading: false , loadingDialog: "" , loadingDialogShow: false});
    };
    handleCloseLoading = () => {
        this.setState({loadingFading: true});
        setTimeout(() => {
            this.setState({showLoading: false, loadingFading: false , loadingDialog: "" , loadingDialogShow: false});
        }, 500); // Match this duration with the CSS transition duration
    };
    loadingDialog(dialog) {
        this.setState({loadingDialog: dialog , loadingDialogShow: true});
        setTimeout(() => {
            this.handleCloseLoading();
        } , "1000");
    }

    endConversation() {
        this.handleShowLoading();
        setTimeout(() => {
            let s = document.getElementsByClassName("modal--endverify")[0].getElementsByClassName("name")[0].value;
            if(s.length == 0) return;
            axios.post(
                Config.BASE_URL + "/chat/conversation/finish/"+this.props.id,
                {
                "name": s
                },
                {  
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
                }
            ).then(res => {
                console.log(res);
                let response = res.data.response;
                setTimeout(() => {
                    this.loadingDialog("برنامه با موفقیت ساخته شد! تو قسمت برنامه ها میتونی ببینی");
                }, "100");
                setTimeout(() => {
                    this.props.parentRefresh();
                }, "1500");
                return;
            }).catch(function (error) {
                console.log(error);
                if(error.data == "No active account") {
                    this.handleShowAlert("بسته ای برای حساب شما فعال نشده")
                    setTimeout(() => {
                        this.handleCloseAlert();
                    } , "2000")
                }
                else if(error.data == "Demo plans limited") {
                    this.handleShowAlert("برنامه های شما به سقف خود رسیده! برای مکالمه بیشتر بسته خود را ارتقا دهید")
                    setTimeout(() => {
                        this.handleCloseAlert();
                    } , "2000")
                }
                else if(error.data == "Mentor plans limited") {
                    this.handleShowAlert("برنامه های شما به سقف خود رسیده! برای مکالمه بیشتر بسته خود را ارتقا دهید")
                    setTimeout(() => {
                        this.handleCloseAlert();
                    } , "2000")
                }
                setTimeout(() => {
                    this.setState({catchData: false});
                }, "1000");
            });
        }, 300)
    }
    delConversation() {
        this.handleShowLoading();
        setTimeout(() => {
            axios.post(
                Config.BASE_URL + "/chat/conversation/delete",
                {
                    "conversation_id": this.props.id
                },
                {  
                    headers: {
                        'authorization': this.state.cookies.get("user-token"),
                        "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                    }
                }
            ).then(res => {
                console.log(res);
                let response = res.data.response;
                setTimeout(() => {
                    this.loadingDialog("چت حذف شد!");
                }, "100");
                setTimeout(() => {
                    this.props.parentRefresh();
                }, "1500");
                return;
            }).catch(function (error) {
                console.log(error);
                setTimeout(() => {
                    this.setState({catchData: false});
                }, "1000");
            });
        }, 300)
    }

    openEndConvModal() {
        document.getElementsByClassName("endconv--modal")[0].style.display = "flex";
        document.getElementsByClassName("endconv--modal")[0].style.visibility = "visible";
        setTimeout(() => {
            document.getElementsByClassName("modal--endverify")[0].style.opacity = "1";
        } , "100");
    }
    openDelConvModal() {
        document.getElementsByClassName("delconv--modal")[0].style.display = "flex";
        document.getElementsByClassName("delconv--modal")[0].style.visibility = "visible";
        setTimeout(() => {
            document.getElementsByClassName("modal--delverify")[0].style.opacity = "1";
        } , "100");
    }
    get_all_messages() {
        let response = [] , messages = [];
        axios.get(
            Config.BASE_URL + "/chat/conversation/"+this.props.id,
            // { crossDomain: true },
            {   
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            response = res.data;
            for(let i = 0; i < response.length; i += 1) {
                let message = [];
                message.push(response[i].chat_text);
                if(response[i].from_user == true) message.push("from");
                else message.push("to");
                messages.push(message);
            }
            this.setState({messages: messages , catchData: true});
        }).catch(error => {
            this.handleShowAlert("بسته ای برای حساب شما فعال نشده")
            this.setState({catchData: true});
            setTimeout(() => {
                this.handleCloseAlert();
            } , "2000")
        }); 


    }
    chatHistory() {
        let chats=[];
        let len = this.state.messages.length;
        console.log(len);
        for(let i = 0; i < len; i += 1) {
            if(this.state.messages[i][1] != "from") {
                chats.push( <div className='rightchat'>
                                <div className='textbox'>
                                    <span className='text'>{this.state.messages[i][0]}</span>
                                </div>
                                <div className='logobox'>
                                    <div className='logo'><img src={icon}></img></div>
                                </div>
                            </div>
                );
            }
            else {
                chats.push( <div className='leftchat'>
                                <div className='textbox'>
                                    <span className='text'>{this.state.messages[i][0]}</span>
                                </div>
                            </div>
                );
            }
        }
        return chats;
    }
    streamChat() {
        this.autoscroll()
        if(this.state.streamDialog == null) {
            if(this.state.streamLoading == true) {
                return( <div className='streambox' >
                            <div className='textbox'>
                                <span className='text' onChange={() => this.autoscroll()}>
                                    <ReactTyped 
                                        strings={["..."]} typeSpeed={50} showCursor={false} startDelay={0} loop={true}
                                        style={{whiteSpace: 'pre-line' , color: "green"}}
                                    />
                                </span>
                            </div>
                        </div>
                );
            }
            else {
                return (
                    <div className='streambox'></div>
                );
            }
        }
        console.log(this.state.streamDialog);
        return(
            <div className='streambox' >
                <div className='textbox'>
                    <span className='text' onChange={() => this.autoscroll()}>
                        <ReactTyped 
                            strings={[this.state.streamDialog]} typeSpeed={10} showCursor={false} startDelay={0} loop={false}
                            style={{whiteSpace: 'pre-line' , color: "black"}} onBegin={() => this.autoscrollStart()} onComplete={()=> this.autoscrollEnd()}
                        />
                    </span>
                </div>
            </div>
        );
    }

    send_message(s) {
        console.log("message:");
        console.log(s);
        if(s.length < 1) return;

        axios.post(
            Config.BASE_URL + "/chat/v2/conversation/send/"+this.props.id,
            {
              "text": s
            },
            {  
              headers: {
                'authorization': this.state.cookies.get("user-token"),
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
              }
            }
        ).then(res => {
            console.log(res.data.response);
            let response = res.data.response;
            setTimeout(() => {
                this.setState({streamDialog: response});
                this.setState({streamLoading: false});
            }, "600");
            return;
        }).catch(function (error) {
            console.log(error);
            if(error.data == "No active account") {
                this.handleShowAlert("بسته ای برای حساب شما فعال نشده")
                setTimeout(() => {
                    this.handleCloseAlert();
                } , "2000")
            }
            else if(error.data == "Demo chat limited") {
                this.handleShowAlert("مکالمه شما به سقف خود رسیده! برای مکالمه بیشتر بسته خود را ارتقا دهید")
                setTimeout(() => {
                    this.handleCloseAlert();
                } , "2000")
            }
        });
    }
    shortcut(e) {
        if ((window.innerHeight < window.innerWidth) && e.key == "Enter" && !e.shiftKey) {
            e.preventDefault();
            this.update_display();
        }
        setTimeout(() => {
            let s = document.getElementById('inputbox').value;
            s = s.trim();
            // input button style
            if(s.length == 0) {
                document.getElementById('inputbutton').style.opacity = "0.2";
            }
            else {
                document.getElementById('inputbutton').style.opacity = "1";
            }
        }, "100");
    }
    autoscroll() {
        if(document.getElementsByClassName('static--chats').length == 0) return;
        console.log("scroll");
        var elem = document.getElementsByClassName('static--chats')[0];
        elem.scrollTo({
            top: elem.scrollHeight,
            behavior: 'smooth'
        });
    }
    autoscrollStart() {
        // console.log("shuru");
        scrollinv = setInterval(() => this.autoscroll() , "300");
    }
    autoscrollEnd() {
        // console.log("payan");
        clearInterval(scrollinv);
        scrollinv = null;
    }
    update_display() {
        if(this.state.streamLoading == true) return
        // input value
        let s = document.getElementById('inputbox').value;
        s = s.trim();
        // reset input box
        document.getElementById('inputbox').value = "";

        setTimeout(() => {
            document.getElementById('inputbutton').style.opacity = "0.2";
        }, "500")
            
        if(s.length < 1)
            return

        let updated = this.state.messages;
        if(this.state.streamDialog != null) {
            updated.push([document.getElementsByClassName('streambox')[0].textContent , "to"]);
            // let last = "<div class='leftchat'><div class='textbox'><span class='text'>" + document.getElementsByClassName('streambox')[0].textContent + "</span></div></div>";
            // document.getElementsByClassName('chats--end')[0].insertAdjacentHTML("beforebegin" , last);
        }
        // let elem = document.getElementsByClassName('streambox')[0];
        // document.getElementsByClassName('streambox')[0].remove();
        
        updated.push([s , "from"]);
        this.setState({messages: updated});
        // let last = "<div class='rightchat'><div class='textbox'><span class='text'>" + s + "</span></div></div>";
        // document.getElementsByClassName('chats--end')[0].insertAdjacentHTML("beforebegin" , last);
        // this.autoscroll();
        
        this.setState({streamLoading: true});
        this.setState({streamDialog: null});
        setTimeout(() => {
            this.autoscroll();
            this.send_message(s);
        }, 200)
        

        // update states
        // setTimeout(() => {
        //     this.setState({index: this.state.index + 1});
        //     this.setState({dialog: response});
        //     document.getElementsByClassName('chats--end')[0].insertAdjacentElement("afterend" , elem);
        // }, "600");
    }
    textareachange() {
        const textarea = document.getElementById('inputbox');
        const textareamargin = document.getElementById('inputbox--margin');

        textarea.addEventListener('input', function() {
            // Reset the height to auto to calculate the new height
            this.style.height = 'auto';
            textareamargin.style.height = 'auto';
            // Set the height to the scrollHeight to fit the content
            let val = this.scrollHeight;
            textareamargin.style.height = val + 10 + 'px';
            this.style.height = val + 'px';
        });

        textarea.addEventListener('focus', () => {
            setTimeout(() => {
                textarea.setSelectionRange(0, 0); // Set cursor to the start
            }, 0); // Use a timeout to ensure it runs after the focus event
        });
    }

    render() {
        if(this.state.lastId == null || this.state.lastId != this.props.id) {
            this.setState({lastId: this.props.id , catchData: false , streamDialog: null , streamLoading: false , messages:[]});
        }
        if(this.props.id == null) {
            return;
        }
        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_all_messages();
            }, 500)
            return(
                <>
                    <Loading visible={true} showDialog={false}/>
                    {this.state.showAlert && (
                        <MyAlert 
                            text={this.state.alertText}
                            close={this.handleCloseAlert} 
                            visible={!this.state.alertFading} 
                            color="rgb(50,100,50)"
                            textcolor="rgb(10,10,10)"
                        />
                    )}
                </>
            );
        }
        return (
            <div className='chatbox'>
                <div className='static--chats' onChange={() => this.autoscroll()}>
                    {this.chatHistory()}
                    <div className='chats--end'></div>
                    {this.streamChat()}
                    {this.autoscroll()}
                    <div className='chatbox--input'>
                        <div className='inputbox--margin' id='inputbox--margin'>
                            <textarea id='inputbox' cols="30" rows="1" onChange={() => this.textareachange()} onKeyDown={(e) => this.shortcut(e)}></textarea>
                            <button className='inputbutton' id='inputbutton' onClick ={() => this.update_display()}><img src={upicon}></img></button>
                        </div>
                    </div>
                </div>
                <div className='controlbox'>
                    <button className='controlbox done' onClick={() => this.openEndConvModal()}>ثبت رژیم</button>
                    <button className='controlbox delete' onClick={() => this.openDelConvModal()}>حذف گفتگو</button>
                    <button className='controlbox back' onClick={() => this.props.parentCloseChat()}>برگشت</button>
                </div>
                
                <div className='endconv--modal'>
                    <div className='modal--endverify'>
                        <input className='name' placeholder='عنوان رژیم را وارد کنید'></input>
                        <div className='topic'><span className='txt'>از صحت اطلاعات رژیم اطمینان دارید؟</span></div>
                        <button className='agree' onClick={() => this.endConversation()}>اطمینان دارم</button>
                        <button className='disagree'>برگشت</button>
                    </div>
                    {/* <div className='modal--endverify loading' style={{display:'none'}}>
                        <Loading successDialog={"رژیم با موفقیت ساخته شد میتوانید از پنل کاربری مشاهده کنید"} errorDialog={"خطایی پیش آمده دوباره تلاش کنید!"}/>
                    </div> */}
                </div>
                <div className='delconv--modal'>
                    <div className='modal--delverify'>
                        <div className='topic'><span className='txt'>از حذف این گفتگو اطمینان دارید؟</span></div>
                        <button className='agree' onClick={() => this.delConversation()}>اطمینان دارم</button>
                        <button className='disagree'>برگشت</button>
                    </div>
                    {/* <div className='modal--delverify loading' style={{display:'none'}}>
                        <Loading successDialog={'گفتگو با موفقیت حذف شد'} errorDialog={'خطایی پیش آمد دوباره تلاش کنید'}/>
                    </div> */}
                </div>

                {this.state.showAlert && (
                    <MyAlert 
                        text={this.state.alertText}
                        close={this.handleCloseAlert} 
                        visible={!this.state.alertFading} 
                        color="rgb(50,100,50)"
                        textcolor="rgb(10,10,10)"
                    />
                )}

                {this.state.showLoading && (
                    <Loading visible={!this.state.loadingFading} showDialog={this.state.loadingDialogShow} dialog={this.state.loadingDialog}/>
                )}
            </div>
        );
    }
}

export default Chat