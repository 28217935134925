import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import '../styles/Alert.css'

const { Config } = require('../config');


class MyAlert extends React.Component { 
    constructor(props) {
        super(props)
    } 

    render() {
        return(
            <div className={`alert ${this.props.visible ? 'fade-in' : 'fade-out'}`} style={{backgroundColor: this.props.color}}>
                <div className='content'>
                    <span className='txt' style={{color: this.props.textcolor}}>{this.props.text}</span>
                </div>
                <button className='close' onClick={() => this.props.close()}>بستن</button>
            </div>
        );
    }
}

export default MyAlert