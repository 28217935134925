import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';
import Blog from './Blog/Blog';
import Profile from './Profile/Profile';
import TPlans from './Plans/TPlans';

var scrollinv = null;

class Viewboard extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        if(this.props.type == "meals") {
            return (
                <div className='tpanel--viewboard'>
                    <TPlans />
                </div>
            );
        }
        if(this.props.type == "blog") {
            return (
                <div className='tpanel--viewboard'>
                    <Blog />
                </div>
            );
        }
        if(this.props.type == "profile") {
            return (
                <div className='tpanel--viewboard'>
                    <Profile />
                </div>
            );
        }
    }
}

export default Viewboard