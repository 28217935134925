import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

// import '../../../styles/Cpanel/Profile.css'
// import '../../../styles/Cpanel/Profile_v2.css'
import { Config } from '../../../config';


class Sign extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , sigRef: createRef()};

        this.clearCanvas = this.clearCanvas.bind(this);
        this.sendCanvas = this.sendCanvas.bind(this);
    }

    clearCanvas() {
        this.state.sigRef.current.clear();
    }

    sendCanvas() {

    }

    render() {
        return (
            <div className='profile--sign'>
                <div className='topic'>
                    <span className='topic'>با ثبت نماد یا امضای دلخواه خود در صفحه ی زیر آن را در برنامه های تمرینی یا تغذیه به اشتراک بگذارید</span>
                </div>
                <div className='canvas'>
                    <SignatureCanvas penColor={'white'}
                        canvasProps={{className: 'sigCanvas'}}
                        backgroundColor={'rgb(50,50,50)'}
                        clearOnResize={'false'}
                        dotSize={2}
                        velocityFilterWeight={0.1}
                        ref={this.state.sigRef}
                    />
                </div>
                <button className='clear' onClick={() => this.clearCanvas()}>خالی کردن</button>
                <button className='send' onClick={() => this.sendCanvas()}>ثبت نماد</button>
                <button className='back' onClick={() => {this.props.parentRefresh();}}>برگشت</button>
            </div>
        );
    }
}

export default Sign