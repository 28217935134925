import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Profile/Editinfo.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';
import ImageUploader from './ImageUploader';


class EditInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , refreshDoc: false , loadingPage: false , loadingSuccessDialog: "" , loadingErrorDialog: "" , catchedData: false , smsSent: false , mode: "info" , name: "" , username: "" , firstname: "" , lastname: "" , email: "" , phone: "" , password: "" , verified: ""};
    }

    rerender() {
        this.setState({refreshDoc: false , loadingPage: false , loadingSuccessDialog: "" , loadingErrorDialog: "" , catchedData: false , smsSent: false , mode: "info" , username: "" , firstname: "" , lastname: "" , email: "" , phone: "" , password: "" , verified: ""});
    }
    getData() {
        axios.get(
            Config.BASE_URL + "/user/cprofile/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            this.setState({catchedData: true , username: res.data.username , name: res.data.first_name + " " + res.data.last_name , firstname: res.data.first_name , lastname: res.data.last_name , email: res.data.email , phone: res.data.phone_number , password: res.data.password , verified: res.data.is_verified});
        }).catch(error => {
            console.log(error);
        });
    }

    loadingSuccess(dialog) {
        this.setState({loadingSuccessDialog : dialog});
        if(document.getElementsByClassName("loading--logo").length > 0) document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        if(document.getElementsByClassName("loading--success").length > 0)  document.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        }, "1000");
    }

    loadingError(dialog) {
        this.setState({loadingErrorDialog : dialog});
        if(document.getElementsByClassName("loading--logo").length > 0) document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        if(document.getElementsByClassName("loading--error").length > 0) document.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        }, "1000");
    }

    loadingReset() {
        if(document.getElementsByClassName("loading--logo").length > 0) document.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        if(document.getElementsByClassName("loading--error").length > 0)  document.getElementsByClassName("loading--error")[0].style.display = 'none';
        if(document.getElementsByClassName("loading--success").length > 0) document.getElementsByClassName("loading--success")[0].style.display = 'none';
        this.setState({loadingPage: false});
    }

    setMode(s) {
        setTimeout(() => {
            this.setState({mode : s});
        }, "500")
    }

    changePassword() {

    }

    sendedit(t) {
        let name = this.state.name;
        let username = this.state.username;
        let phone = this.state.phone;
        if(t == 0) {
            let flag = this.state.name_edit;
            this.setState({name_edit: !flag});
            if(flag) {
                name = document.getElementById("nameedit").value;
                this.senddata(name , username , phone);
                this.setState({name: name});
            }
        }
        if(t == 1) {
            let flag = this.state.username_edit;
            this.setState({username_edit: !flag});
            if(flag) {
                username = document.getElementById("usernameedit").value;
                this.senddata(name , username , phone);
                this.setState({username: username});
            }
        }
        if(t == 2) {

        }
    }

    leave_account() {
        this.state.cookies.remove("user-token");
        this.state.cookies.remove("username");
        this.state.cookies.remove("role");
        setTimeout(() => {window.location.href="/";}, "300")
    }

    render() {
        if(this.state.catchedData == false) {
            setTimeout(() => {
                this.getData();
            }, "500");
            return (
                <Loading visible={true} showDialog={false}/>
            );
        }
        
        if(this.state.mode == "info") {
            return (
                <div className='profile--user'>
                    <div className='info'>
                        <div className='namebox'>
                            <input className='name' disabled={!this.state.name_edit} value={this.state.name}></input>
                            <button className='button' id='nameedit' onClick={() => this.sendedit(0)}>{(this.state.name_edit == true ? "ثبت" : "ویرایش")}</button>
                        </div>
                        <div className='usernamebox'>
                            <input className='username' disabled={!this.state.username_edit} value={this.state.username}></input>
                            <button className='button' id='usernameedit' onClick={() => this.sendedit(1)}>{(this.state.username_edit == true ? "ثبت" : "ویرایش")}</button>
                        </div>
                        <div className='phonebox'>
                            <input className='name' disabled={!this.state.phone_edit} value={this.state.phone}></input>
                            <button className='button' id='phoneedit' onClick={() => this.sendedit(2)}>{(this.state.phone_edit == true ? "ثبت" : "ویرایش")}</button>
                        </div>
                    </div>
                    <div>
                        <ImageUploader />
                    </div>
                    <div className='controlbox'>
                        <button className='leave' onClick={() => this.leave_account()}>خروج از حساب</button>
                        <button className='changepassword' onClick={() => {this.setMode("changepassword")}}>تغییر رمز</button>
                    </div>
                    <div className='deleteaccount'>
                        <button className='back'>حذف حساب</button>
                    </div>
                </div>
            );
        }
        if(this.state.mode == "changepassword") {
            return(
                <div className='profile--user'>
                    <div className='changepassword'>
                        <input className='password curr' placeholder='رمز فعلی'></input>
                        <input className='password new' placeholder='رمز جدید'></input>
                        <button className='button' onClick={() => {this.changePassword();}}>تایید</button>
                        <button className='back' onClick={() => {this.setMode("info");}}>برگشت</button>
                    </div>
                </div> 
            );
        }
    }
}

export default EditInfo