import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import Cookies from 'universal-cookie';
import Diets from './Diet/Diets';

import DietIcon from '../../../../files/icons/dietplan.png'
import WorkoutIcon from '../../../../files/icons/workoutplan.png'

class Plans extends React.Component {
    constructor(props) {
        super(props)

        this.state = {cookies: new Cookies()};
        this.rerender = this.rerender.bind(this);
    }

    rerender() {
        this.setState({cookies: new Cookies() , plansMode: null});
    }


    render() {
        return (
            <div className='plans' style={{padding:"0"}}>
                <span className='topic'>برنامه‌ها</span>
                <div className='mode diet' onClick={() => {setTimeout(() => {window.location.href = "/Cpanel/plans/diet"} , "500")}}>
                    <img src={DietIcon}></img>
                    <span>تغذیه</span>
                </div>
                <div className='mode workout' onClick={() => {setTimeout(() => {window.location.href = "/Cpanel/plans/workout"} , "500")}}>
                    <img src={WorkoutIcon}></img>
                    <span>تمرین</span>
                </div>
            </div>
        );
    }
}

export default Plans