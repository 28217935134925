import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Wallet.css'
import '../../../styles/Cpanel/Wallet_v2.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';


class DepositeVerify extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , catched_data: false , ref_id: null , status: null};
    }

    verify(authority) {
        axios.post(
            Config.BASE_URL + "/payment/payverify/",
            {
                authority: authority,
            },
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res.data);
            if(res.data.status == "payment succeed") {
                this.setState({catched_data: true , ref_id: res.data.ref_id , status: "OK"});
            }
        }).catch(error => {
            console.log(error);
            this.setState({catched_data: true , status: "NOK"});
        });
    }

    render() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const status = params.get('Status');
        const authority = params.get('Authority');
        if(this.state.catched_data == false) {
            this.verify(authority);
            return(
                <div className='deposite_verify'>
                    <Loading visible={true} showDialog={false}/>
                </div>
            );
        }

        if(this.state.status == "OK") {
            return(
                <div className='deposite_verify'>
                    <div className='succeed'>
                        <div className='topic'>
                            <span>عملیات پرداخت با موفقیت انجام شد!</span>
                        </div>
                        <div className='ref'>
                            <p>شناسه پرداخت: <span>{this.state.ref_id}</span></p>
                        </div>
                        <div className='controlbox'>
                            <button className='back' onClick={() => {window.location.href="/"}}>پنل کاربری</button>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return(
                <div className='deposite_verify'>
                    <div className='failed'>
                        <div className='topic'>
                            <span>عملیات پرداخت انجام نشد!</span>
                        </div>
                        <div className='controlbox'>
                            <button className='back' onClick={() => {window.location.href="/"}}>پنل کاربری</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default DepositeVerify