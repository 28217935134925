import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import Cookies from 'universal-cookie';
import axios from 'axios';
import Loading from '../../../Loading/Loading';
import '../../../styles/Cpanel/Trainees/Requests.css';
const { Config } = require('../../../config');

class Requests extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , requests:[] , requestsInfo:[] , catchData: false};
        this.rerender = this.rerender.bind(this);
    }

    rerender() {
        this.setState({catchData: false , requests:[] , requestsInfo:[]});
    }

    get_requests() {
        let response = [] , requestsId = [] , infos = [];
        axios.get(
            Config.BASE_URL  +"/user/coachrequests/",
            {   
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
            response = res.data;

            let len = response[0].length;
            for(let i = 0; i < len; ++i) {
                requestsId.push(response[0][i]);
                infos.push([response[1][i] , response[2][i]]);
            }
            this.setState({requests: requestsId , requestsInfo: infos , catchData: true});
        })
    }

    send(res , index) {
        axios.post(
            Config.BASE_URL  +"/user/requesthandler/",
            {   
                id: this.state.requests[index],
                status: res,
            },
            {   
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
            let response = res.data;

            this.rerender();
        })
    }

    requestcmp(index) {
        return(
            <div className='request' id={"request"+index.toString()}>
                <div className='details'>
                    <p className='from'>نام: <span className='name'>{this.state.requestsInfo[index][0]}</span></p>
                    <p className='phone'>همراه: <span className='phone'>{this.state.requestsInfo[index][1]}</span></p>
                </div>
                <div className='controlbox'>
                    <button className='accept' onClick={() => this.send("Accepted" , index)}>تایید</button>
                    <button className='reject' onClick={() => this.send("Rejected" , index)}>رد</button>
                </div>
            </div>
        );
    }
    requests() {
        let list = [];
        let len = this.state.requests.length;
        for(let i = 0; i < len; i += 1) {
            list.push(this.requestcmp(i));
        }
        return list;
    }   

    render() {
        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_requests();
            }, 500)
            return(
                <div className='requests_page' style={{padding: "0"}}>
                    <Loading visible={true} showDialog={false}/>
                </div>
            );
        }

        return (
            <div className='requests_page'>
                <div className='controlbox'>
                    <button className='back' onClick={() => setTimeout(() => {window.history.back()} , "500")}>&lt;</button>
                    <div className='topic'><span>Requests</span></div>
                </div>
                <div className='pending'>
                    {this.requests()}
                </div>
            </div>
        );
    }
}

export default Requests