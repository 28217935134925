import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import logo from '../../files/logo2.png'

import Cookies from 'universal-cookie';

import '../styles/Forgot/forgot.css';
import Loading from '../Loading/Loading';
import Menubar from '../Home/Menubar';

const { Config } = require('../config');

class Forgotpassword extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , loadingDialog: "" , showLoading: false , loadingFading: false , log: "" , tokenVerified: false}
    } 

    handleShowLoading = () => {
        this.setState({showLoading: true, loadingFading: false , loadingDialog: "" , loadingDialogShow: false});
    };
    handleCloseLoading = () => {
        this.setState({loadingFading: true});
        setTimeout(() => {
            this.setState({showLoading: false, loadingFading: false , loadingDialog: "" , loadingDialogShow: false});
        }, 500); // Match this duration with the CSS transition duration
    };
    loadingDialog(dialog) {
        this.setState({loadingDialog: dialog , loadingDialogShow: true});
        setTimeout(() => {
            this.handleCloseLoading();
        } , "2000");
    }

    setPhoneNumber() {
        let phone_number = document.getElementsByClassName("phone")[1].value;
        if(phone_number.length < 10) {
            this.setState({log: "شماره همراه وارد شده موجود نمیباشد"});
            setTimeout(() => {
                this.setState({log: ""})
            }, "5000")
            return;
        }

        this.setState({log: ""});
        this.handleShowLoading();
        axios.post(
            Config.BASE_URL + "/user/forgotaccount/",
            {
                phone_number: phone_number
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
                }
            }
        ).then(res => {
            this.loadingDialog("لینک برای تغییر رمز عبور ارسال شد")
        }).catch(error => {
            if(error.response.data.error == "Invalid phone number") {
                this.loadingDialog("شماره همراه وارد شده موجود نمیباشد");
            }
            else {
                this.loadingDialog("خطایی پیش آمده دوباره تلاش کنید")
            }
        });
    }

    tokenVerify() {
        axios.post(
            Config.BASE_URL + "/user/forgottoken/",
            {
                token: this.props.token
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
                }
            }
        ).then(res => {
            this.setState({tokenVerified: true});
        }).catch(error => {
            if(error.response.data.error == "Invalid token") {
                this.loadingDialog("لینک معتبر نمیباشد")
                setTimeout(() => {
                    window.location.href = "/";
                } , "3000")
            }
            else {
                this.loadingDialog("خطایی پیش آمده دوباره تلاش کنید")
            }
        });
    }

    setPassword() {
        let password = document.getElementsByClassName("newpassword")[0].value;
        let passwordVerify = document.getElementsByClassName("newpassword")[1].value;
        if(password.length < 4) {
            this.setState({log: "رمز عبور نباید کمتر از ۴ حرف باشد"});
            setTimeout(() => {
                this.setState({log: ""})
            }, "5000")
            return;
        }
        if(password != passwordVerify) {
            this.setState({log: "رمز با تاییدیه آن برابر نمیباشد"});
            setTimeout(() => {
                this.setState({log: ""})
            }, "5000")
            return;
        }

        this.setState({log: ""});
        this.handleShowLoading();
        axios.post(
            Config.BASE_URL + "/user/resetpassword/",
            {   
                token: this.props.token,
                password: password
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
                } 
            }
        ).then(res => {
            this.loadingDialog("رمز عبور با موفقیت تغییر یافت");
            setTimeout(() => {
                window.location.href = "/login";
            } , "3000")
        }).catch(error => {
            if(error.response.data.error == "Invalid token") {
                this.loadingDialog("لینک معتبر نمیباشد")
                setTimeout(() => {
                    window.location.href = "/";
                } , "3000")
            }
            else {
                this.loadingDialog("خطایی پیش آمده دوباره تلاش کنید")
            }
        });
    }

    render() {
        if(this.props.token == null) {
            return(
                <div className='forgot'>
                    <Menubar />
                    <div className='form phone'>
                        <span className='topic'>شماره همراه خود را وارد کنید</span>
                        <input className='phone' placeholder='شماره همراه'></input>
                        <button className='send' onClick={() => this.setPhoneNumber()}>تایید</button>
                        <span className='log--error'>
                            <ReactTyped 
                                strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                            />
                        </span>
                    </div>
                    {this.state.showLoading && (
                        <Loading visible={!this.state.loadingFading} showDialog={this.state.loadingDialogShow} dialog={this.state.loadingDialog}/>
                    )}
                </div>
            );
        }

        if(this.state.tokenVerified == false) {
            return(
                <div className='forgot' onLoad={() => this.tokenVerify()}>
                    <Menubar />
                    <div className='form token' style={{display: "none"}}></div>
                    {this.state.showLoading && (
                        <Loading visible={!this.state.loadingFading} showDialog={this.state.loadingDialogShow} dialog={this.state.loadingDialog}/>
                    )}
                </div>
            );
        }

        else {
            return (
                <div className='forgot'>
                    <Menubar />
                    <div className='form password'>
                        <span className='topic'>رمز جدید را وارد کنید</span>
                        <input className='newpassword' placeholder='رمز عبور'></input>
                        <input className='newpassword verify' placeholder='تایید رمز'></input>
                        <button className='send' onClick={() => this.setPassword()}>تایید</button>
                        <span className='log--error'>
                            <ReactTyped 
                                strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                            />
                        </span>
                    </div>
                    {this.state.showLoading && (
                        <Loading visible={!this.state.loadingFading} showDialog={this.state.loadingDialogShow} dialog={this.state.loadingDialog}/>
                    )}
                </div>
            );
        }
    }
}

export default Forgotpassword