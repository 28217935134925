import React from 'react';
import ReactDOM from 'react-dom/client';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Home/Home.css'
import '../styles/Home/Home_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture1.png'
import Loading from '../Loading/Loading'
import Menubar from './Menubar';
import axios from 'axios';

import home2 from '../../files/Walls/home.jpg'
import { toHaveStyle } from '@testing-library/jest-dom/matchers';

const { Config } = require('../config');

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , catch_data:false , coaches: 0, currentBanner: 0 , bannersCount: 0 , loadedCount: 0 , lastMove: Date.now() , countbegin: false,
            partners_name: [] , partners_image: [], partners_mode:[]
        };
    }  

    get_coaches() {
        let cnt = 0;
        axios.get(
            Config.BASE_URL + "/user/countofcoaches/",
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
              }
            }
        ).then(res => {
            console.log(res);
            cnt = res.data.countof_coaches;
            this.setState({coaches:cnt});
            // console.log(cnt);
        }).catch(error => {
            console.log(error);
        });
    }

    initialize() {
        let cnt = document.getElementsByClassName('banner').length;
        this.setState({bannersCount: cnt});
        var maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        for (let i = 0; i < cnt; i = i + 1) {
            document.getElementsByClassName('banner ' + String(i))[0].style.opacity = "0";
        }
        for (let i = 0; i < cnt; i = i + 1) {
            document.getElementsByClassName('banner ' + String(i))[0].style.right = "0px";
        }
        // console.log("fuck");
        document.getElementsByClassName('banner 0')[0].style.opacity = "1";
        document.getElementsByClassName("boxBanners--loading")[0].style.display = 'none';
        setInterval(() => {
            this.sliderMove(1 , 0);
        }, "1000")
    }
    loadComplete() {
        if(this.state.loadedCount == this.state.bannersCount - 1) {
            document.getElementsByClassName("boxBanners--loading")[0].style.display = 'none';
            // console.log("fuck u");
            document.getElementsByClassName('banner 0')[0].style.opacity = "1";
        }
        this.setState({loadedCount: this.state.loadedCount + 1});
    }
    sliderMove(dir , flag) {
        if(flag == 1 || Date.now() - this.state.lastMove > 5000) {
            let newBanner = (this.state.currentBanner + dir + this.state.bannersCount)%this.state.bannersCount;
            this.setPosition(newBanner , dir);
            setTimeout(() => {
                this.moveBanner(this.state.currentBanner , dir);
                this.moveBanner(newBanner , dir);
                this.sliderRefresh(newBanner);
            } , "300");
            this.setState({lastMove: Date.now()});
        }
    }
    sliderRefresh(newBanner) {
        this.setState({currentBanner: newBanner});
        for(let i = 0; i < this.state.bannersCount; i = i + 1) {
            if(i != newBanner) document.getElementsByClassName("banner " + String(i))[0].style.opacity = "0";
        }
        for(let i = 0; i < this.state.bannersCount; i = i + 1) {
            document.getElementsByClassName("banner " + String(i))[0].style.right = "0px";
        }
    }

    moveBanner(id , dir) {
        var maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let pos =  document.getElementsByClassName("banner " + String(id))[0].style.right;
        pos = pos.substring(0 , pos.length - 2);
        let currRight = parseInt(pos);
        document.getElementsByClassName("banner " + String(id))[0].style.right = String(currRight + dir * -1 * maxWidth) + "px";
    }
    setPosition(id , dir) {
        var maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let pos = dir * maxWidth;
        document.getElementsByClassName("banner " + String(id))[0].style.right = String(pos) + "px";
        setTimeout(() => {
            document.getElementsByClassName("banner " + String(id))[0].style.opacity = "1";
        }, "300");
    }

    bannersElem() {
        // var is = (window.screen.availHeight > window.screen.availWidth);
        var is = false;
        const banners = (!is ? require.context('../../files/banners/GYMi/JPG/GYMi/Chosen', true) : require.context('../../files/banners/GYMi/LOGO/poster', true));
        const bannersList = banners.keys().map(banner => banners(banner));
        let bannersElement = [];
        for (let i = 0; i < bannersList.length; i = i + 1) {
            bannersElement.push(<img className={'banner ' + i} src={bannersList[i]} style={{opacity: "0"}} onLoad={() => this.loadComplete()}></img>)
        }
        return bannersElement;
    }

    linkfield() {
        if(this.state.cookies.get('user-token') == null) {
            return(
                <button className='button panel' onClick={() => {window.location.href = "/login"}}>ورود</button>
            );
        }
        else {
            if(this.state.cookies.get('role') == "coach" || this.state.cookies.get('role') == "Coach" || this.state.cookies.get('role') == "Coache") {
                return(
                    <button className='button panel' onClick={() => {window.location.href = "/Cpanel"}}>پنل کاربری</button>
                );
            }
            else {
                return(
                    <button className='button panel' onClick={() => {window.location.href = "/Tpanel"}}>پنل کاربری</button>
                );
            }
        }
    }

    animateCounter(elementId, targetCount, duration) {
        const element = document.getElementById(elementId);
        const increment = targetCount / (duration / 16); // Approx. 16ms per frame
        let currentCount = 0;
        this.setState({countbegin: true});

        function updateCounter() {
            currentCount += increment;
            if (currentCount < targetCount) {
                element.textContent = Math.floor(currentCount);
                requestAnimationFrame(updateCounter);
            } else {
                element.textContent = targetCount; // Ensure the final value is exact
            }
        }

        updateCounter();
    }

    get_partners() {
        let names = [] , images = [] , modes= [];
        axios.get(
            Config.BASE_URL + "/user/getpartners/",
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
              }
            }
        ).then(res => {
            console.log(res);
            names = res.data.names;
            images = res.data.images;
            for (let i = 0; i < names.length; ++i) {
                modes.push(false)
            }
            this.setState({partners_image: images , partners_name: names , partners_mode:modes , catch_data: true});
        }).catch(error => {
            console.log(error);
        });
    }

    partnerMode(id) {
        let elem = document.getElementById("partner " + id.toString());
        let img = elem.getElementsByTagName("img")[0];
        let sp =  elem.getElementsByTagName("span")[0];
        let modes = this.state.partners_mode;
        if(modes[id] == false) {
            modes[id] = true;
            img.style.opacity = "0.3";
            sp.style.display = "flex";
            setTimeout(() => {
                sp.style.opacity = "1";
            } , "200");
        }
        else {
            modes[id] = false;
            sp.style.opacity = "0";
            setTimeout(() => {
                sp.style.display = "none";
            } , "200");
            img.style.opacity = "1";
        }
        this.setState({partners_mode:modes});
    }

    partners() {
        let list = [] , modes = [];
        let len = this.state.partners_name.length;
        if (len > 4) len = 4;
        for (let i = 0; i < len; ++i) {
            list.push(
                <div className='partner' id={'partner ' + i.toString()} onClick={() => this.partnerMode(i)}>
                    <img src={this.state.partners_image[i]}></img>
                    <span>{this.state.partners_name[i]}</span>
                </div>
            );
        }
        return list;
    }

    get_donates() {

    }

    donates() {

    }

    render() {
        if(this.state.coaches == 0 && this.state.catch_data == false) {
            this.get_coaches();
            this.get_partners();
            this.get_donates();
        }
        if(this.state.coaches > 0 && this.state.countbegin == false) {
            this.animateCounter("user-counter", 10 + this.state.coaches, 3000); 
            setTimeout(() => {
                document.getElementById("box-user-counter").style.opacity = "1";
            } , "500");
        }
        var is = (window.screen.availHeight > window.screen.availWidth);
        if(is) {
            return (
                <div className = "home">
                    <Menubar />
                    {/* <img className="background" id='background' src={home2}></img> */}
                    <div className='boxUsers'>
                        <p id='box-user-counter'>تا الان <span id='user-counter'>0</span> مربی از جیمی استفاده میکنن</p>
                    </div>
                    <div className='boxBanners' onLoad={() => this.initialize()}>
                        {this.bannersElem()}
                        <span className='sliderLeft' onClick={() => this.sliderMove(-1 , 1)}>&lt;</span>
                        <div className='boxBanners--loading'>
                            <Loading />
                        </div>
                        <span className='sliderRight' onClick={() => this.sliderMove(1 , 1)}>&gt;</span>
                    </div>
                    <div className='boxPartners'>
                        <div className='topic'>
                            <span>همکاران</span>
                        </div>
                        <div className='partners'>
                            {this.partners()}
                        </div>
                        <div className='all'>
                            <button>مشاهده همه</button>
                        </div>
                    </div>
                    <div className='boxDonates'>
                        <div className='topic'>
                            <span>حامیان ما</span>
                        </div>
                        <div className='donates'>
                            {this.donates()}
                        </div>
                        <div className='all'>
                            <button>من هم حمایت میکنم</button>
                        </div>
                    </div>
                    <div className='boxButtons'>
                        <button className='button packages' onClick={() => {window.location.href = "/packages"}}>بسته ی مربیگریت رو بساز!</button>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className = "home">
                    <Menubar />
                    <div className='boxUsers'>
                        <p id='box-user-counter'>تا الان <span id='user-counter'>0</span> مربی از جیمی استفاده میکنن</p>
                    </div>
                    <div className='boxBanners' onLoad={() => this.initialize()}>
                        {this.bannersElem()}
                        <span className='sliderLeft' onClick={() => this.sliderMove(-1 , 1)}>&lt;</span>
                        <div className='boxBanners--loading'>
                            <Loading />
                        </div>
                        <span className='sliderRight' onClick={() => this.sliderMove(1 , 1)}>&gt;</span>
                    </div>
                </div>
            );
        }
    }
}

export default Home;